<template>
    <div class="container">
        <headers></headers>
        <right></right>
        <scrollpage></scrollpage>

        <div  class="w box_sizing width_box my_wrap">
            <div class="left_position">
                <left></left>
            </div>

            <div class="banner_detail box_sizing">
                <div class="strategy_list box_sizing flex_box flex_align_center flex_justify_between" v-for="(item, index) in list" :key='index'  @click="jumpStrategy(item)">
                    <div class="strategy_name">{{item.title}}</div>
                    <div class="strategy_jump flex_box flex_justify_between flex_align_center">
                        详情 <img src="../assets/images/jump_you.png" class="strategy_jump_img" alt="">
                    </div>
                </div>
            </div>
        </div>
        <footers></footers>
    </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
var _this;
export default {
    components: {
        headers,
        footers,
        left,
        right,
        scrollpage,
    },
    data() {
        return {
            content: '',
            id: '',
            list: []
        }
    },
    created() {
        _this = this;
        _this.getList()
    },
    mounted() {},
    methods: {

        // 跳转攻略详情
        jumpStrategy(item) {
            console.log(item)
            let path = this.$router.resolve({
                path:'/strategyDetail',
                query: {
                    id: item.id,
                    title: item.title,
                    updatetime: item.updatetime
                }
            })
            window.open(path.href,'_blank')
        },

        // 获取攻略详情
        getList() {
            _this.$axios.post('faq/getList', {}).then(res => {
                console.log(res);
                if(res.code == 1) {
                    _this.list = res.data;
                }else {
                    _this.$message.error(res.msg);
                }
            }).catch(err => {
                console.log(err)
            })
        },
    }
    
}
</script>


<style>
    .banner_detail {
        width: 100%;
        padding: 32px 86px;
        border-radius: 20px;
        background: rgba(255, 255, 255, 1);
        text-align: center;
    }

    .strategy_list {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid rgba(238, 238, 238, 1);
        cursor: pointer;
    }

    .strategy_name {
        font-size: 16px;
        color: rgba(6, 18, 30, 1);
    }

    .strategy_list:hover .strategy_name {
        color: rgba(246, 112, 75, 1);
    }

    .strategy_jump {
        font-size: 14px;
        color: rgba(246, 112, 75, 1);
        cursor: pointer;
    }

    .strategy_jump_img {
        width: 16px;
        height: 16px;
        margin-left: 6px;
    }
</style>
