<template>
    <div class="container">
        <headers></headers>
        <right></right>
        <scrollpage></scrollpage>

        <div class="w box_sizing width_box my_wrap">
            <div class="left_position">
                <left></left>
            </div>

            <div class="my_couse_wrap box_sizing">
                <div class="my_couse_top box_sizing flex_box">
                    <div class="my_couse_top_list box_sizing flex_box flex_diection flex_align_center" @click="typeChange(1)">
                        <div class="my_couse_top_list_txt" :class="[type == 1 ? 'now' : '']">已购课程</div>
                        <div class="my_couse_top_list_line" v-if="type == 1"></div>
                    </div>
                    <div class="my_couse_top_list box_sizing flex_box flex_diection flex_align_center" @click="typeChange(2)">
                        <div class="my_couse_top_list_txt" :class="[type == 2 ? 'now' : '']">推荐课程</div>
                        <div class="my_couse_top_list_line" v-if="type == 2"></div>
                    </div>
                </div>
                <div class="my_couse_box flex_box flex-warp" v-if="type == 1">
                    <div class="my_couse" v-for="(item, index) in list" :key='"couse" + index'>
                        <div class="my_couse_img">
                            <img :src="item.course.image" alt="">
                        </div>
                        <div class="my_couse_bottom box_sizing">
                            <div class="my_couse_title box_sizing overflow2">{{item.course.name}}</div>
                            <div class="my_couse_info box_sizing flex_box flex_justify_between flex_align_center">
                                <div class="my_couse_info_money">￥{{item.course.price}}</div>
                                <div class="my_couse_info_num">学习人数：<span>{{item.course.number}}</span></div>
                            </div>
                            <div class="my_couse_btns box_sizing flex_box flex_justify_between flex_align_center">
                                <div class="my_couse_btn flex_1 flex_box flex_justify_center flex_align_center" @click="jumpDetail(item.course.id)">立即学习</div>
                                <div class="my_couse_btn flex_1 flex_box flex_justify_center flex_align_center" @click="showStatus(item)" v-if='item.status == 2'>评价课程</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="my_couse_box flex_box flex-warp" v-if="type == 2">
                    <div class="my_couse" v-for="(item, index) in list" :key='"couse" + index'>
                        <div class="my_couse_img">
                            <img :src="item.image" alt="">
                        </div>
                        <div class="my_couse_bottom box_sizing">
                            <div class="my_couse_title box_sizing overflow2">￥{{item.name}}</div>
                            <div class="my_couse_info box_sizing flex_box flex_justify_between flex_align_center">
                                <div class="my_couse_info_money">￥{{item.price}}</div>
                                <div class="my_couse_info_num">学习人数：<span>{{item.number}}</span></div>
                            </div>
                            <div class="my_couse_btns box_sizing flex_box flex_justify_between flex_align_center">
                                <div class="my_couse_btn flex_1 flex_box flex_justify_center flex_align_center" @click="jumpDetail(item.id)">立即学习</div>
                                <div class="my_couse_btn flex_1 flex_box flex_justify_center flex_align_center" @click="showStatus(item)"  v-if='item.is_order != 0 && item.status == 1'>评价课程</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pinglun_bottom box_sizing" v-if='total > 5 && type == 1'>
                    <el-pagination
                        background
                        :page-size='5'
                        layout="prev, pager, next"
                        @current-change='pageChange'
                        :total="total">
                    </el-pagination>
                </div>

            </div>
            
        </div>

        <footers></footers>


        <div class="couse_pinglun_wrap" v-if="status">
            <div class="couse_pinglun_box box_sizing">
                <div class="couse_pinglun_top box_sizing flex_box flex_justify_between">
                    <div class="couse_linglun_img">
                        <img :src="imageSrc" alt="">
                    </div>
                    <div class="couse_linglun_info flex_1 flex_box flex_diection flex_justify_between">
                        <div class="couse_linglun_info_title overflow2">{{title}}</div>
                        <div class="couse_linglun_info_bottom flex_box">
                            <div class="flex_1 couse_linglun_info_money">¥{{money}}</div>
                            <div class="flex_1 couse_linglun_info_num">学习人数：<span>{{renNum}}</span></div>
                        </div>
                    </div>
                </div>
                <div class="couse_pinglun_star_box box_sizing flex_box flex_align_center">
                    <div class="couse_pinglun_star_left">评分</div>
                    <div class="couse_pinglun_star_right box_sizing flex_box flex_align_center">
                        <div class="couse_pinglun_star" v-for="(item, index) in imgList" :key='index' @click="startClick(index)">
                            <img :src="item" alt="">
                        </div>
                    </div>
                </div>
                <div class="text_content_wrap box_sizing">
                    <textarea v-model="content" placeholder="请输入评价内容"></textarea>
                </div>
                <div class="text_content_btns box_sizing flex_box flex_align_center flex_justify_center">
                    <div class="text_content_btn box_sizing flex_box flex_align_center flex_justify_center" @click="status = false">取消</div>
                    <div class="text_content_btn box_sizing flex_box flex_align_center flex_justify_center nos" @click='submitStart'>确定</div>
                </div>
            </div>
        </div>



    </div>
</template>


<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
import "@/css/my.css";
var _this;
export default {
    components: {
        headers,
        footers,
        left,
        right,
        scrollpage,
    },
    data() {
        return {
            content: '',
            status: false,
            type: 1,
            avatar: '',
            name: '',
            page: 1,
            list: [],
            total: 0,
            title: '',
            money: 0,
            renNum: 0,
            imageSrc: '',
            start: 0,
            courseId: '',
            weiSrc: require("../assets/images/weixuan_star.png"),
            xuanSrc: require("../assets/images/yixuan_star.png"),
            imgList: [require("../assets/images/weixuan_star.png"), require("../assets/images/weixuan_star.png"), require("../assets/images/weixuan_star.png"), require("../assets/images/weixuan_star.png"), require("../assets/images/weixuan_star.png")]
        }
    },
    created() {
        _this = this;
        _this.getMyCouse()
    },
    mounted() {

    },
    methods: {

        // 提交对该课程的评价
        submitStart() {
            let params = {
                order_id: _this.courseId,
                score: _this.start,
                content: _this.content
            }
            if(_this.start == 0) {
                _this.$message.error('请先进行评分');
            }else {
                _this.$axios.post('course.course_comment/addCourseComment', params).then(res => {
                    console.log(res);
                    if(res.code == 1) {
                        _this.$message.success('评价成功');
                        _this.status = false;
                        
                    }else {
                        _this.$message.error(res.msg);
                    }
                    
                    
                }).catch(err => {
                    console.log(err)
                })
            }
            
        },

        // 评价星星
        startClick(i) {
            console.log(i)
            let list = _this.imgList;
            for(var j = 0; j < list.length; j++) {
                 if(j <= i) {
                    list[j] = require("../assets/images/yixuan_star.png")
                }else {
                    list[j] = require("../assets/images/weixuan_star.png")
                }
            }
            _this.start = i + 1;
            console.log(_this.start);
            _this.imgList = list;
            _this.$forceUpdate();
        },

        // 显示评价课程
        showStatus(obj) {
            console.log(obj);
            _this.title = obj.course.name;
            _this.money = obj.course.price;
            _this.renNum = obj.course.number;
            _this.imageSrc = obj.course.image;
            _this.courseId = obj.id;
            _this.status = true;
        },
        
        // 课程跳转详情
        jumpDetail(id) {
            console.log(id)
            this.$router.push({
                path:'/couseDetail',
                query: {
                    id: id
                }
            })
        },

        // 改变type
        typeChange(type) {
            _this.type = type;
            _this.page = 1;
            if(type == 1) {
                _this.getMyCouse()
            }else {
                _this.getTuiJian()
            }
        },

        // 获取推荐课程
        getTuiJian() {
            let d = {
                limit: 9
            }
            _this.$axios.post('course.course/getCourseRandList', d).then(res => {
                console.log(res);
                if(res.code == 1) {
                    _this.list = res.data
                    
                }else {
                    _this.$message.error(res.msg);
                }
            }).catch(err => {
                console.log(err)
            })
        },

        // 获取我购买的课程
        getMyCouse() {
            let d = {
                page: _this.page,
                per_page: 9
            }
            _this.$axios.post('user/getUserCourse', d).then(res => {
                console.log(res);
                if(res.code == 1) {
                    _this.total = res.data.total;
                    _this.list = res.data.data
                }else {
                    _this.$message.error(res.msg);
                }
            }).catch(err => {
                console.log(err)
            })
        },

        // 改变页码时
        pageChange(page) {
            _this.page = page;
            // _this.getCourseComment();
        },
    }
}
</script>

<style>
    .my_wrap {
        min-height: 600px;
    }
</style>