<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>
    <word ref="word"></word>

    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>
      <div class="question_list_wrap box_sizing">
        <div class="flex_box flex_align_center listtitle">
          <img src="../assets/images/words.png" alt="" />{{ title }}
        </div>
        <div class="flex_align_center flex_box flex_justify_between wordTip">
          <div class="flex_align_center flex_box">
            <input
              type="checkbox"
              class="hand_hover"
              :checked="selectAllStatus"
              @click="toall"
            />
            <div>全选</div>
            <div class="shengci hand_hover" @click="toShuciMulti(1)">
              全部标记生词
            </div>
            <div class="shuci hand_hover" @click="toShuciMulti(2)">全部标记熟词</div>
            <img
              v-if="id == 0"
              @click="delAllWoed"
              src="../assets/images/del.png"
              alt=""
            />
          </div>
          <div class="flex_box flex_align_center">
            <div>排序：</div>
            <el-dropdown @command="wordType">
              <div
                class="el-dropdown-link flex_align_center flex_box all hand_hover"
              >
                {{word_type_text}}<i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="[0,'全部']">全部</el-dropdown-item>
                <el-dropdown-item :command="[1,'生词']">生词</el-dropdown-item>
                <el-dropdown-item :command="[2,'熟词']">熟词</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown  @command="addTime">
              <div class="flex_align_center flex_box all hand_hover">
                {{add_time_text}}<i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="[1, '添加时间']"
                  >添加时间</el-dropdown-item
                >
                <el-dropdown-item :command="[2, '背诵次数']"
                  >背诵次数</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown  @command="sortTime">
              <div class="flex_align_center flex_box all hand_hover">
                排序<i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="1">升序</el-dropdown-item>
                <el-dropdown-item :command="2">降序</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div style="padding-top: 24px">
          <div
            class="flex_align_center flex_box flex_justify_between wordTip"
            style="padding: 16px 0"
            v-for="(item, index) in words"
            :key="index"
          >
            <div class="flex_box flex_align_center">
              <input
                type="checkbox"
                class="hand_hover"
                :checked="item.flag"
                @click="tosigle(index)"
              />
              <div
                class="circle"
                :style="item.status == 2 ? 'background:#67ddbf' : ''"
              ></div>

              <!-- <div class="circle" v-if="item.status == 1"></div>
              <div class="f_c" v-if="item.status == 2"></div> -->

              <div style="cursor: pointer;" @click.stop="dianjiClick(item.word.word)">{{ item.word.word }}</div>
            </div>
            <div class="flex_align_center flex_box">
              <div>背诵{{ item.num }}次</div>
              <div
                class="shengci hand_hover"
                @click="toShuci(1, $event)"
                :data-id="item.word_id"
                :data-word="item.word.word"
              >
                标记生词
              </div>
              <div
                class="shuci hand_hover"
                @click="toShuci(2, $event)"
                :data-id="item.word_id"
                :data-word="item.word.word"
              >
                标记熟词
              </div>
              <img
                class="hand_hover"
                src="../assets/images/del.png"
                alt=""
                @click="delWord(3, $event)"
                :data-id="item.word_id"
                v-if="id == 0"
              />
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="block">
          <el-pagination
            class="fenye"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="prev, pager, next,total"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
import Word from "@/components/word.vue";
var _this;
export default {
  components: {
    headers,
    footers,
    left,
    right,
    scrollpage,
    Word
  },
  data() {
    return {
      id: "", //单词本id
      title: "",
      words: [],
      status: "", //状态0全部,1=生词,2=熟词
      sort_field: "", //添加时间
      sort: "", //1升序，2降序
      page: 1,
      total: 0, //总条数

      word_id: "", //单词id
      //
      // orderList: [],
      // 全选装填
      selectAllStatus: false,
      add_time_text: "添加时间",
      word_type_text: "全部",
    };
  },
  created() {
    _this = this;
    _this.getParams();
  },
  methods: {
    // 获取传递过来的参数
    getParams() {
      _this.id = _this.$route.query.id;
      _this.title = _this.$route.query.title;
      _this.getList();
    },

    // 获取列表
    getList() {
      let params = {
          word_book_id: _this.id,
          status: _this.status,
          sort_field: _this.sort_field,
          sort: _this.sort,
          page: _this.page,
          per_page: 10,
        },
        url = "";
      if (_this.id == 0) {
        url = "word.user_word_book/lis";
      } else {
        url = "word.user_word_book/bookList";
        // params.word_book_id = _this.id;
      }
      _this.$axios
        .post(url, params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            let list = res.data.data;

            list.forEach((el) => {
              el.flag = false;
            });

            _this.words = list;
            _this.total = res.data.total;
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 点击事件
    dianjiClick(item) {
      console.log(item);
      this.$refs.word.word_text = item;
      this.$refs.word.getWord();
    },
    // 添加时间,背诵次数
    addTime(e) {
      _this.sort = e[0];
      _this.add_time_text = e[1];
      _this.getList(); 
    },
    // 排序
    sortTime(e) {
      _this.sort_field = e;

      _this.getList();
    },
    wordType(e) {
      _this.status = e[0];
      _this.word_type_text = e[1];
      _this.getList();
    },
    // 标记，删除
    toShuciMulti(num) {
      console.log(num);
      let type = num;

      let url = "word.user_word_book/changeStatus";
      let params = {
        word_book_id: _this.id,
        word_id: _this.word_id,
        type: type,
      };
      console.log(params);
      var list = _this.words;
      _this.$axios
        .post(url, params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            let word_ids = _this.word_id.split(",");
            list.forEach((item) => {
              word_ids.forEach((item2)=>{
                console.log('ssss',item.word_id,item2);
                if (item.word_id == item2) {
                  if (type == 1) {
                    item.status = 1;
                    item.num = item.num + 1;
                  } else if (type == 2) {
                    item.status = 2;
                    item.num = item.num + 1;
                  }
                }
              })
            });
            _this.words = list;
            if (type == 1 || type == 2) {
              _this.$message({
                message: "标记成功",
                type: "success",
              });
            } else if (type == 3) {
              _this.$message({
                message: "删除成功",
                type: "success",
              });
              _this.page = 1;
              _this.total = 0;
              _this.words = [];
              _this.getList();
            }
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // // 标记，删除
    toShuci(num, e) {
      console.log(num);
      console.log(e.target.dataset.id);
      console.log(e.target.dataset.word);
      let type = num;
      _this.word_id = e.target.dataset.id;

      let url = "word.user_word_book/changeStatus";
      let params = {
        word_book_id: _this.id,
        word_id: _this.word_id,
        type: type,
      };

      console.log(params);

      var list = _this.words;
      _this.$axios
        .post(url, params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            list.forEach((item) => {
              if (item.word_id == _this.word_id) {
                if (type == 1) {
                  item.status = 1;
                  item.num = item.num + 1;
                } else if (type == 2) {
                  item.status = 2;
                  item.num = item.num + 1;
                }
              }
            });

            _this.words = list;

            if (type == 1 || type == 2) {
              _this.$message({
                message: "标记成功",
                type: "success",
              });
            } else if (type == 3) {
              _this.$message({
                message: "删除成功",
                type: "success",
              });
              _this.page = 1;
              _this.total = 0;
              _this.words = [];
              _this.getList();
            }
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // TODO:删除我的单词
    delWord(num, e) {
      let type = num;
      _this.word_id = e.target.dataset.id;

      let url = "word.user_word_book/changeStatus";
      let params = {
        word_book_id: _this.id,
        word_id: _this.word_id,
        type: type,
      };

      this.$confirm("是否删除该单词?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.$axios
            .post(url, params)
            .then((res) => {
              console.log(res);
              if (res.code == 1) {
                _this.$message({
                  message: "删除成功",
                  type: "success",
                });
                _this.page = 1;
                _this.total = 0;
                _this.words = [];
                _this.getList();
              } else {
                _this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // TODO:批量删除我的单词
    delAllWoed() {
      let url = "word.user_word_book/changeStatus";
      let params = {
        word_book_id: this.id,
        word_id: this.word_id,
        type: 3,
      };

      // console.log(this.word_id);
      if (this.word_id == "") return;

      this.$confirm("是否删除所有单词?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _this.$axios
            .post(url, params)
            .then((res) => {
              console.log(res);
              if (res.code == 1) {
                _this.$message({
                  message: "删除成功",
                  type: "success",
                });
                _this.page = 1;
                _this.total = 0;
                _this.words = [];
                _this.getList();
              } else {
                _this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 全选
    toall(e) {
      console.log(e);
      var list = this.words;
      var orderList = [];
      //全选按钮---取反
      this.selectAllStatus = !this.selectAllStatus;

      list.forEach((item) => {
        item.flag = this.selectAllStatus;
        if (this.selectAllStatus) {
          orderList.push(item.word_id);
        } else {
          orderList = [];
        }
      });
      //更新data中的数据
      this.words = list;
      this.word_id = orderList.join(",");
    },

    // 单选
    tosigle(e) {
      console.log(e);
      var list = this.words;
      var flag = list[e].flag;
      var orderList = [];
      list[e].flag = !flag;
      this.words = list;
      // console.log(orderList);
      let is_all = true;
      list.forEach((item) => {
        console.log(item);
        if (item.flag) {
          orderList.push(item.word_id);
        } else {
          is_all = false;
        }
      });
      this.selectAllStatus = is_all;

      this.word_id = orderList.join(",");
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      _this.page = val;
      _this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.selectAllStatus = false;
      _this.page = val;
      _this.getList();
    },
  },
};
</script>


<style>
.listtitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 44px;
  color: #b90038;
}
.listtitle img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.wordTip {
  border-bottom: 1px solid #eeeeee;
}
.wordTip input {
  width: 30px;
  height: 30px;
  transform: scale(0.6);
  border: 1px solid black;
  margin-right: 6px;
}
.wordTip img {
  width: 20px;
  height: 20px;
  margin-left: 22px;
}
.wordTip .shengci {
  width: 115px;
  height: 24px;
  background: linear-gradient(180deg, #f5a65c 0%, #fb9739 100%);
  opacity: 1;
  border-radius: 12px;
  color: #ffffff;
  text-align: center;
  line-height: 24px;
  margin: 0 16px 0 24px;
}
.wordTip .shuci {
  width: 115px;
  height: 24px;
  background: linear-gradient(180deg, #67ddbf 0%, #31b99e 100%);
  opacity: 1;
  border-radius: 12px;
  color: #ffffff;
  text-align: center;
  line-height: 24px;
}
.wordTip .all {
  font-style: 16px;
  color: #8c9198;
  margin-left: 30px;
}
.all img {
  width: 12px;
  height: 12px;
  margin-left: 6px;
}
.wordTip .circle {
  width: 16px;
  height: 16px;
  background: linear-gradient(180deg, #f5a65c 0%, #fb9739 100%);
  border-radius: 50%;
  opacity: 1;
  margin-right: 10px;
}
.f_c {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  opacity: 1;
  margin-right: 10px;
  background: linear-gradient(180deg, #67ddbf, #31b99e);
}
.fenye {
  margin: 20px auto 0;
  /* width: 30%; */
  /* margin-left: auto; */
  display: flex;
  justify-content: flex-end;
}
input[type="checkbox"]:checked {
  color: #31b99e;
  background: #31b99e;
}
</style>
