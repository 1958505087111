<template>
  <div class="topicinfo">
    <div class="topic_type_name topic_type_name_asq">{{ topicInfo.number }} {{ topicInfo.name }}</div>
    <div class="topic_type_info box_sizing flex_box flex_align_center">
      <div class="topic_type_info_time">{{ topicInfo.createtime_text }}</div>
      <!--      <div class="question_lian_shoucang">-->
      <!--        <img-->
      <!--          class="question_list_jiangjieimg"-->
      <!--          v-if="!topicInfo.collect || topicInfo.collect == 1"-->
      <!--          src="../assets/images/shoucang1.png"-->
      <!--          alt=""-->
      <!--        />-->
      <!--        <img-->
      <!--          class="question_list_jiangjieimg"-->
      <!--          v-if="topicInfo.collect == 2"-->
      <!--          src="../assets/images/shoucang2.png"-->
      <!--          alt=""-->
      <!--        />-->
      <!--        <img-->
      <!--          class="question_list_jiangjieimg"-->
      <!--          v-if="topicInfo.collect == 3"-->
      <!--          src="../assets/images/shoucang3.png"-->
      <!--          alt=""-->
      <!--        />-->
      <!--        <img-->
      <!--          class="question_list_jiangjieimg"-->
      <!--          v-if="topicInfo.collect == 4"-->
      <!--          src="../assets/images/shoucang4.png"-->
      <!--          alt=""-->
      <!--        />-->
      <!--        <img-->
      <!--          class="question_list_jiangjieimg"-->
      <!--          v-if="topicInfo.collect == 5"-->
      <!--          src="../assets/images/shoucang5.png"-->
      <!--          alt=""-->
      <!--        />-->
      <!--      </div>-->
      <!--      <img-->
      <!--        src="../assets/images/type_fenxiang.png"-->
      <!--        class="topic_type_info_fenxiang"-->

      <!--        alt=""-->
      <!--      />-->
    </div>
    <div class="topic_type_title" v-if="topicInfo.topic_type_id !== 1 && topicInfo.topic_type_id !== 7">
    
      <div v-if="topicInfo.topic_type_id == 2" v-html="topicInfo.show_tape_text"></div>
      <div v-for="(item, index) in topicInfo.show_text" :key="index" >
              <div v-if="item !=='<br/>'" class="panding_change">
                      <span
                        v-html="item "

                      ></span>
              </div>
              <div v-else style="display: flex;width: 100vw">

              </div>
            </div>
    </div>
    <div v-if="topicInfo.topic_type_id === 7" class="topic_type_title">
      <!-- <div v-html="topicInfo.show_text">
      </div> -->
      <div v-for="(item, index) in topicInfo.show_text" :key="index">
          <div v-if="item !== '<br/>'" class="panding_change">
            <span v-html="item"></span>
            <span class="text-show" style="width: 0;"></span>
          </div>
          <div v-else style="display: flex;width: 100vw">
          </div>
        </div>
    </div>
    <!--      阅读填空-->
    <div v-if="topicInfo.topic_type_id === 11" class="topic_type_timu_box box_sizing">
      <span v-for="(item, index) in topicInfo.extend.FIB" :key="index">
        <div v-if="item.index == 0" v-html="item.word"></div>
        <div v-else class="panding_change"
          style="height: 16px;border-bottom: 1px solid rgba(6, 18, 30, 1);width: 150px;text-align: center;"></div>
      </span>
      <div v-if="topicInfo.extend.keyword" class="box_sizing flex_box flex-warp" style="width: 100%">
        <div v-for="(item, index) in topicInfo.extend.keyword" :key="index">
          <div class="yuansu_list box_sizing flex_box flex_align_center flex_justify_center" v-html="item"></div>
        </div>
      </div>
    </div>
    <div class="mp3_tool" v-if="topicInfo.topic_type_id == 1 && (topicInfo.extend.fast != '' || topicInfo.extend.slow != '')">
      <div style="display: flex">
        <span style="flex: 1" v-if="topicInfo.extend.slow != ''">朗读示范(常速)</span>
        <span style="flex: 1" v-if="topicInfo.extend.fast != ''">朗读示范(慢速)</span>
      </div>
      <div style="display: flex;margin: 20px 0px">
        <div style="flex: 1" v-if="topicInfo.extend.fast != ''">
          <mp3-tool ref="mp3" :src-url="qiniuUrl + topicInfo.extend.fast"></mp3-tool>
        </div>
        <div style="flex: 1" v-if="topicInfo.extend.slow != ''">
          <mp3-tool ref="mp3" :src-url="qiniuUrl + topicInfo.extend.slow"></mp3-tool>
        </div>
      </div>
    </div>
    <div class="mp3_tool2" v-if="topicInfo.topic_type_id == 1 && (topicInfo.extend.fast != '' || topicInfo.extend.slow != '')">
      <div style="width: 100%;">
        <span style="" v-if="topicInfo.extend.slow != ''">朗读示范(常速)</span>
        <div style="margin: 10px 0;" v-if="topicInfo.extend.fast != ''">
          <mp3-tool ref="mp3" :src-url="qiniuUrl + topicInfo.extend.fast"></mp3-tool>
        </div>
      </div>
      <div style="width: 100%;">
        <span style="" v-if="topicInfo.extend.fast != ''">朗读示范(慢速)</span>
        <div style="margin: 10px 0;" v-if="topicInfo.extend.slow != ''">
          <mp3-tool ref="mp3" :src-url="qiniuUrl + topicInfo.extend.slow"></mp3-tool>
        </div>
      </div>
    </div>
    <div v-if="topicInfo.file">
      <!--todo 听写句子+录音总结+识别错词+补全对话+听力单选+-->

      <div v-if="topicInfo.topic_type_id === 1 || topicInfo.topic_type_id === 2 || topicInfo.topic_type_id === 4 ||
      topicInfo.topic_type_id === 5 || topicInfo.topic_type_id === 20 || topicInfo.topic_type_id === 13 ||
      topicInfo.topic_type_id === 14 || topicInfo.topic_type_id === 15 || topicInfo.topic_type_id === 16 ||
      topicInfo.topic_type_id === 17 || topicInfo.topic_type_id === 18 || topicInfo.topic_type_id === 19 || topicInfo.topic_type_id === 22
      || topicInfo.topic_type_id === 25"
        class="my_padding box_sizing">
        <mp3-tool ref="mp3" :src-url="topicInfo.file"></mp3-tool>
      </div>
      <!--大作文和总结全文-->
      <div v-if="topicInfo.topic_type_id === 6 || topicInfo.topic_type_id === 23" class="topic_type_timu_box box_sizing">
        <div v-for="(item, index) in topicInfo.show_text" :key="index">
          <div v-if="item !== '<br/>'" class="panding_change">
            <span v-html="item"></span>
            <span class="text-show"></span>
          </div>
          <div v-else style="display: flex;width: 100vw">
          </div>
        </div>
      </div>
      <!--阅读单选-->
      <div v-if="topicInfo.topic_type_id === 8">
        <div class="topic_type_timu_box box_sizing hand_change">
          <div v-for="(item, index) in topicInfo.show_text" :key="index">
            <div v-if="item !== '<br/>'" class="panding_change">
              <span v-html="item"></span>
              <span class="text-show"></span>
            </div>
            <div v-else style="display: flex; width: 100vw"></div>
          </div>
        </div>
        <div class="topic_type_answer_box_h5 box_sizing">
          <div class="my_questins4 flex_box">
            <div v-if="topicInfo.extend" class="my_questins4_title flex_box">
              {{ topicInfo.extend.topic }}
            </div>
            <div v-if="topicInfo.extend" class="my_questins4_box flex_box">
              <div v-for="(item, index) in topicInfo.extend.extend_SA" :key="index"
                class="my_questins4_list flex_box hand_change">
                <img src="../assets/images/weixuanzhong_icon@2x.png" />

                <div>
                  {{ item.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--阅读多选-->
      <div v-if="topicInfo.topic_type_id === 9">
        <div class="topic_type_timu_box box_sizing">
          <span v-for="(item, index) in topicInfo.show_text" :key="index">
            <span v-if="item != '<br/>'" style="margin-right: 4px">{{ item }}&nbsp;</span>
            <br v-else />
          </span>
        </div>
        <div class="topic_type_answer_box_h5 box_sizing">
          <div class="my_questins4 flex_box">
            <div class="my_questins4_title flex_box" v-if="topicInfo.extend">
              {{ topicInfo.extend.topic }}

            </div>
            <div class="my_questins4_box flex_box" v-if="topicInfo.extend">
              <div class="my_questins4_list flex_box hand_change" v-for="(item, index) in topicInfo.extend.extend_MA"
                :key="index">
                <img src="../assets/images/checkbox_nochoose.png" />

                <div>
                  {{ item.key }}. {{ item.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  段落排序-->

      <div v-if="topicInfo.topic_type_id === 10">
        <div class="flex_align_center flex_box" style="height: 100%">
          <!--          <div class="topic_type_answer_box box_sizing">-->
          <!--            <div class="title17">Source</div>-->
          <!--            <div-->
          <!--              v-for="(item, index) in choose"-->
          <!--              :key="index"-->
          <!--              :class="chooseOne == index ? 'choose' : ''"-->
          <!--              :data-text="item"-->
          <!--              class="flex_box flex_align_center box_sizing choose17"-->
          <!--              draggable="true"-->

          <!--            >-->
          <!--              <div class="p17">{{ item.show_index }}</div>-->
          <!--              <div :class="chooseOne == index ? 'chooseP' : ''">-->
          <!--                {{ item.content }}-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <img-->
          <!--            alt=""-->
          <!--            src="../assets/images/choose.png"-->
          <!--            style="width: 44px; height: 44px; margin: 0 15px"-->
          <!--          />-->
          <div class=" box_sizing" style="position: relative; height: 100%">
            <div class="title17">Correct Answer</div>
            <transition-group name="flip-list">
              <div v-for="(item, index) in topicInfo.extend.extend_sort" :key="index"
                :class="chooseTwo == index ? 'choose' : ''" class="flex_box flex_align_center box_sizing choose17"
                draggable="true">
                <!--                <div class="p17">{{ item.show_index }}</div>-->
                <div :class="chooseTwo == index ? 'chooseP' : ''">
                  {{ item.content }}
                </div>
              </div>
            </transition-group>

          </div>
        </div>
      </div>

    </div>
    <!--阅读写作填空-->
    <div v-if="topicInfo.topic_type_id === 12" class="topic_type_timu_box box_sizing">
      <template v-for="(item, index) in topicInfo.extend.FIB_RW">
        <span v-if="typeof item.word == 'string'" :key="index" v-html="item.word" style="line-height: 30px;"></span>

        <select v-if="Array.isArray(item.word)" :key="index" style="font-size: 15px">
          <option value=""></option>
          <option v-for="(items, indexs) in item.word" :key="indexs" :value="items.word" v-html="items.word">
          </option>
        </select>
      </template>
    </div>
    <!--识别错词-->
    <div class="topic_type_answer_box_h5 box_sizing" style="word-break: break-all;" v-if="topicInfo.topic_type_id === 19">
      <span class="cuoci_ans hand_change" v-for="(item, index) in topicInfo.extend.HIW"
        :class="[item.bg == 1 ? 'bg_ans' : '']" :key="index" v-html="item.word"></span>
    </div>
    <!--补全对话+听力单选+摘要选择-->
    <div class=" box_sizing"
      v-if="topicInfo.topic_type_id === 16 || topicInfo.topic_type_id === 17 || topicInfo.topic_type_id === 18">
      <div class="my_questins4 flex_box">
        <div class="my_questins4_title flex_box" v-if="topicInfo.extend">
          {{ topicInfo.extend.topic }}

        </div>
        <div class="my_questins4_box flex_box" v-if="topicInfo.extend">
          <div class="my_questins4_list flex_box" v-for="(item, index) in topicInfo.extend.extend_SA" :key="index">
            <img src="../assets/images/weixuanzhong_icon@2x.png" />
            <div :class="[item.select == 2 ? 'my_questins4_color' : '']" class="hand_change">
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--听力填空-->
    <div v-if="topicInfo.topic_type_id === 15" class="topic_type_answer_box_h5 box_sizing">
      <div class="topic_type_timu_now_box box_sizing" v-if="topicInfo.extend && topicInfo.extend.FIB">
        <span v-for="(item, index) in topicInfo.extend.FIB" :key="index">
          <span v-if="item.index == 0" v-html="item.word"></span>
          <input type="text" class="kong" v-if="item.index == 1" value="" :data-daan="item.word" ref="inputValue" />
        </span>
      </div>
    </div>
    <!--听力多选-->
    <div v-if="topicInfo.topic_type_id === 14" class="topic_type_answer_box_h5 box_sizing">
      <div class="my_questins4 flex_box">
        <div v-if="topicInfo.extend" class="my_questins4_title flex_box">
          {{ topicInfo.extend.topic }}

        </div>
        <div v-if="topicInfo.extend" class="my_questins4_box flex_box">
          <div v-for="(item, index) in topicInfo.extend.extend_MA" :key="index" class="my_questins4_list flex_box">
            <img src="../assets/images/checkbox_nochoose.png" />
            <div :class="[item.select == 2 ? 'my_questins4_color' : '']">
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--描述图片-->
    <div class="topic_type_answer_box_h5 box_sizing" v-if="topicInfo.topic_type_id === 3">
      <img class="my_quest3" :src="topicInfo.image" style="max-width: 400px;"/>
    </div>
    <!--朗读句子-->
    <div class="topic_type_timu_box box_sizing" v-if="topicInfo.topic_type_id === 1">
      <span v-for="(item, index) in topicInfo.show_text" :key="index" v-html="item"></span>
    </div>



  </div>
</template>

<script>
import Mp3Tool from "@/components/mp3Tool.vue";
export default {
  components: {
    Mp3Tool
  },
  name: 'Topicinfo',
  props: {
    topicInfo: {
      type: Object,
      default: () => ({}) // 设置默认值为空对象
    },
    topicTypeInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      qiniuUrl: "https://yijishen.qiniu.bronet.cn"
    }
  }
};
</script>
<style scoped>
.mp3_tool {
  width: 100%;
}
@media screen and (min-width:750px) {
  .mp3_tool2 {
    display: none;
  }
}
@media screen and (max-width:750px) {
  .mp3_tool {
    display: none;
  }
}
</style>
