<script>
import Navtitle from "@/components/navtitle.vue";
import Topicinfo from "@/components/topicinfo.vue";


export default ({
  components: {
    Navtitle,
    Topicinfo,
  },
  data() {
    return {
      tags:         0,
      topic_id:     "",
      exericise_id: "",
      pinyin:       "",
      title:        "",
      page:         "",
      detail:       {},
      modolStatus:  false,
      currentPage:  {
        page:     1,
        per_page: 10,
        total:    0
      },
      chat:         [],
      back_message: "",
      chat_message: "",
    }
  },
  watch: {
    chat(newVal, oldVal) {
      console.log('当前变动的vue', newVal)
      newVal.forEach(function (item) {
        if (!item.hasOwnProperty("showInput")) {
          item.showInput = false
        }
        if (!item.hasOwnProperty("showLogs")) {
          item.showLogs = false
        }


      })

      this.chat = newVal
    }
  },
  mounted() {
    let params = this.$route.query
    console.log('当前的参数', params)
    let {id, pinyin, title, page} = params
    this.topic_id = id;
    this.pinyin = pinyin;
    this.title = title;
    this.page = page;
    this.getTopicInfo()
    this.getChatList()
  },
  methods: {
    toZan(op) {
      let params = {
        topic_comment_id: op.id
      }
      this.$axios
        .post("exercise.topic_comment_log/log", params)
        .then((res) => {
          if (res.code == 1) {
            this.chat.forEach((item, index) => {
              if (item.id == op.id) {
                if (item.log == 0) {
                  item.log = 1
                  item.number = item.number + 1
                } else {
                  item.log = 0
                  item.number = item.number - 1
                }

              }
            })
          }

        })
        .catch((err) => {
          this.$message.error(err.msg)

          console.log(err);
        });
    },
    pinlun() {
      if (this.chat_message == "") {
        this.$message.error("请填写评论内容");
        return
      }
      let params = {
        topic_id: this.topic_id,
        content:  this.chat_message,

      }
      if (this.tags !== "") {
        params.tags = this.tags
      }

      this.$axios
        .post("exercise.topic_comment/add", params)
        .then((res) => {
          if (res.code == 1) {
            this.$message.success("成功")
            setTimeout(() => {
              location.reload()
            }, 500)
          }

        })
        .catch((err) => {
          this.$message.error(err.msg)

          console.log(err);
        });


    },
    toFeedBack(op){
      if (this.back_message == ""){
        this.$message.error("回复信息不能为空");
        return
      }
      let params = {
        topic_comment_id: op.id,
        content: this.back_message
      }
      this.$axios
        .post("exercise.topic_comment_back/add", params)
        .then((res) => {
          if (res.code == 1) {
            this.$message.success("成功")
            setTimeout(() => {
              location.reload()
            }, 500)
          }

        })
        .catch((err) => {
          this.$message.error(err.msg)

          console.log(err);
        });
    },
    showTextArea(index) {
      // console.log('当前的index', index)
      // console.log('当前的数据', this.chat[index])
      // let ite = this.chat[index];
      // ite.showInput = !ite.showInput
      // this.$set(this.chat, index, ite)

      let chat = this.chat

      chat.forEach((items,indexs)=>{
        console.log('当前的项', items,indexs,index)
        if (indexs == index){
          items.showInput = !items.showInput
        }else {
          items.showInput = false
        }
      })
      this.chat = chat
      this.$forceUpdate()
    },
    showLogs(index) {
      let ite = this.chat[index];
      ite.showLogs = !ite.showLogs
      this.$set(this.chat, index, ite)
    },
    chooseTags(tags) {
      console.log('tags',tags);
      if(tags != this.tags) {
        this.tags = tags
      }
    },
    pageChange(e) {
      this.currentPage.page = e
      this.getChatList()
    },
    getTopicInfo() {
      let _this = this
      var params = {
        topic_id: this.topic_id
      };

      this.$axios
        .post("exercise.topic_exercise/getTopicInfo", params)
        .then((res) => {
          if (res.code == 1) {
            _this.detail = res.data
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getChatList() {
      let _this = this
      var params = {
        page:     this.currentPage.page,
        per_page:     this.currentPage.per_page,
        topic_id: this.topic_id
      };

      this.$axios
        .post("exercise.topic_comment/getList", params)
        .then((res) => {
          if (res.code == 1) {
            _this.chat = res.data.data
            _this.currentPage.total = res.data.total;
            if (res.data.last_page > _this.currentPage.page){
              _this.currentPage.page++
            }
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    seeDaFen() {
      this.modolStatus = true
    }
  }
})
</script>

<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>

    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>

      <div class="question_list_wrap box_sizing">

        <div class="question_type_box box_sizing flex_box flex_align_center flex_justify_between">
          <navtitle :ischoose="2" :page="page" :pinyin="pinyin" :title="title" :topic_id="topic_id"></navtitle>
        </div>
        <div class="topic_wrap">
          <topicinfo :topic-info="detail" :topic-type-info="detail.topic_type"></topicinfo>
        </div>
        <div class=" box_sizing flex_box flex_align_center flex_justify_between">
          <div class="chat-input">
            <div class="chat-input-tags">
              <span :class="tags == 0?'ischoosetag':''" class="tags-item overhand" @click="chooseTags(0)">随便说说</span>
              <span :class="tags == 1?'ischoosetag':''" class="tags-item overhand" @click="chooseTags(1)">经验分享</span>
              <span :class="tags == 2?'ischoosetag':''" class="tags-item overhand" @click="chooseTags(2)">求助大神</span>
              <span :class="tags == 3?'ischoosetag':''" class="tags-item overhand" @click="chooseTags(3)">考试经历</span>
              <span :class="tags == 4?'ischoosetag':''" class="tags-item overhand" @click="chooseTags(4)">加油打气</span>
              <span :class="tags == 5?'ischoosetag':''" class="tags-item overhand" @click="chooseTags(5)">试题纠错</span>
            </div>
            <div class="chat-input-textarea">
              <textarea v-model="chat_message"></textarea>
            </div>
            <div class="chat-input-function">
              <span class="function-item" @click="pinlun">评论</span>
              <!--                          <span class="function-item">取消</span>-->
            </div>
          </div>
        </div>
        <div style="height: 20px"></div>
        <div class="main">
          <div class="main-chat-list">
              <div class="main-chat-list-item" v-for="(item,index) in chat" :key="index">
                <div class="avatar">
                  <img :src="item.user_info.avatar" class="item-avatar">
                </div>
                <div class="chat">
                    <div class="chat-top">
                      <div class="chat-top-user">
                        <span class="chat-top-user-nickname">{{item.user_info.nickname}}</span>
                        <span class="chat-top-user-tags">{{item.tags}}</span>
                      </div>
                      <div class="chat-top-message">
                        {{item.content}}
                      </div>
                      <div class="chat-top-function">
                        <div class="chat-createtime">
                          {{item.createtime}}
                        </div>
                        <div class="chat-zan">
                          <div class="overhand" @click="showTextArea(index)">
                            <span class="chat-zan-titel">回复</span>
                            <i v-if="item.showInput == false" class="el-icon-arrow-down"></i>
                            <i v-if="item.showInput == true" class="el-icon-arrow-up"></i>
                          </div>
                          <div class="overhand" style="display: flex;align-items: center" @click="toZan(item)">
                            <img class="chat-zan-img" src="@/assets/image/zan_icon.png" v-if="item.log>0">
                            <img class="chat-zan-img" src="@/assets/image/black_zan.png" v-else>
                            <span>{{ item.log }}</span>
                          </div>
                        </div>
                      </div>
                      <div v-if="item.showInput" class="chat-input">
<!--                        <div class="chat-input-tags">-->
<!--                          <span :class="tags == '随便说说'?'ischoosetag':''" class="tags-item" @click="chooseTags('随便说说')">随便说说</span>-->
<!--                          <span :class="tags == '经验分享'?'ischoosetag':''" class="tags-item" @click="chooseTags('经验分享')">经验分享</span>-->
<!--                          <span :class="tags == '求助大神'?'ischoosetag':''" class="tags-item" @click="chooseTags('求助大神')">求助大神</span>-->
<!--                          <span :class="tags == '考试经历'?'ischoosetag':''" class="tags-item" @click="chooseTags('考试经历')">考试经历</span>-->
<!--                          <span :class="tags == '加油打气'?'ischoosetag':''" class="tags-item" @click="chooseTags('加油打气')">加油打气</span>-->
<!--                          <span :class="tags == '试题纠错'?'ischoosetag':''" class="tags-item" @click="chooseTags('试题纠错')">试题纠错</span>-->
<!--                        </div>-->
                        <div class="chat-input-textarea">
                          <textarea v-model="back_message"></textarea>
                        </div>
                        <div class="chat-input-function">
                          <span class="function-item" @click="toFeedBack(item)">回复</span>
                          <!--                          <span class="function-item">取消</span>-->
                        </div>
                      </div>
                      <div v-if="item.topic_comment_back.length >0" class="chat-logs">
                        <div v-if="item.showLogs == false">
                          <div class="logs-item">
                            <div class="logs-item-info">
                              <span class="logs-item-nickname">{{ item.topic_comment_back[0].user_info.nickname }}</span>
                              <span class="logs-item-createtime">{{ item.topic_comment_back[0].createtime }}</span>
                            </div>
                            <div class="logs-item-des">
                              <span>{{item.topic_comment_back[0].content }}</span>
                              <span v-if="item.topic_comment_back.length - 1 != 0" class="hand_change" @click="showLogs(index)">展开{{ item.topic_comment_back.length - 1 }}人回复 <i class="el-icon-arrow-down"></i></span>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div class="logs-item" v-for="(log,indexlog) in item.topic_comment_back" :key="indexlog">
                            <div class="logs-item-info">
                              <span class="logs-item-nickname">{{ log.user_info.nickname }}</span>
                              <span class="logs-item-createtime">{{ log.createtime }}</span>
                            </div>
                            <div class="logs-item-des">
                              <span>{{log.content }}</span>
                            </div>
                          </div>
                          <div class="logs-item-close">
                            <span class="hand_change" style="margin-right: 1px" @click="showLogs(index)">收起回复 <i class="el-icon-arrow-up"></i></span>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
          </div>
          <div style="display: flex;  justify-content: flex-end;">
            <el-pagination
              :page-size="currentPage.per_page"
              layout="prev, pager, next"
              :total="currentPage.total"
              :current-page="currentPage.page-1"
              @current-change="pageChange"
            />
          </div>
        </div>
      </div>
    </div>

    <footers></footers>

  </div>
</template>

<style scoped>

.chat-logs {
  width: 100%;
  background: #f9f9f9;
  box-sizing: border-box;
  padding: 22px 23px;
  margin-top: 20px;
}

.logs-item {
  display: flex;
  flex-direction: column;

}

.logs-item-info {
  display: flex;
  align-items: center;
}

.logs-item-nickname {
  font-weight: 500;
  font-size: 16px;
  margin-right: 13px;
}

.logs-item-des {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;

}
.logs-item-close {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: right;

}


.chat {
  width: 100%;
}

.main-chat-list-item {
  display: flex;
  border-top: 1px solid #eeeeee;
  padding: 20px;
}

.item-avatar {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  margin-right: 19px;
}
.chat-top-user{
  display: flex;
  align-items: center;
}
.chat-top-user-nickname{
  font-weight: 500;
  font-size:16px ;
}
.chat-top-user-tags{
  line-height: 18px;
  font-size: 10px;
  color: #FFFFFF;
  height: 18px;
  background: #fcb952;
  border-radius: 9px;
  padding: 0px 5px;
  margin-left:10px ;
}
.chat-top-message{
  font-size: 14px;
  font-weight: 400;
  word-wrap: break-word;
  margin-top: 18px;
}
.chat-top-function{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:16px ;
}
.chat-zan{
  display: flex;
  align-items: center;
  width: 100px;
  justify-content: space-around;

}

.chat-zan-titel {
  width: auto;
  margin-right: 3px;
}

.chat-zan-img {
  width: 24px;
  height: 24px;
  margin-right: 1px;
}

.overhand {
  cursor: pointer;
}

.chat-input {
  margin-top: 20px;
  width: 100%;
  height: 174px;
  border: 0.5px solid #bdc4ce;
  border-radius: 4px;
  padding: 18px;
  box-sizing: border-box;
}

.chat-input-tags {
  display: flex;
  box-sizing: border-box;

}

.tags-item {
  padding: 4px 8px;
  font-size: 10px;
  background-color: #f9f9f9;
  margin-right: 10px;
}

.chat-input-textarea {
  width: 100%;
  height: 74px;
  background: #f9f9f9;
  border: 0.5px solid #bdc4ce;
  border-radius: 4px;
  margin-top: 16px;
}

.chat-input-function {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin: 6px auto;

}

.function-item {
  margin-right: 10px;
  padding: 2px 18px;
  font-size: 14px;
  color: #FFFFFF;
  background: #f6704b;
  border-radius: 12px;
  cursor: pointer;
}
.ischoosetag{
  background: #f6704b !important;
  color: #FFFFFF;
  border-radius: 4px;
}


</style>
