<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>

    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>
      <div class="question_list_wrap box_sizing">
        <div class="flex_box flex_align_center">
          <div v-for="(item, index) in words" :key="index">
            <div class="wordone" v-if="item.id == 0">
              <div class="flex_align_center flex_box onetitle">
                {{ item.title }}
              </div>
              <div class="topic_type1_luyin progress">
                <div
                  class="topic_type1_luyin1"
                  :style="'width:' + item.percent + '%'"
                ></div>
              </div>
              <div
                class="flex_box flex_justify_between flex_align_center onenumber"
              >
                <div>已熟记 {{ item.remember_count }}/{{ item.count }}</div>
                <div>{{ item.percent }}%</div>
              </div>
              <div class="goon" @click="toList(item.id,item.title)">继续背单词</div>
            </div>
          </div>
        </div>

        <div class="flex_align_center flex_box fenlei">
          <img src="../assets/images/words.png" alt="" />分类词汇表
        </div>

        <div class="flex_box flex_align_center flex-warp">
          <div v-for="(item, index) in words" :key="index">
            <div class="wordone" v-if="item.id != 0">
              <div class="flex_align_center flex_box onetitle">
                {{ item.title }}
              </div>
              <div class="topic_type1_luyin progress">
                <div
                  class="topic_type1_luyin1"
                  :style="'width:' + item.percent + '%'"
                ></div>
              </div>
              <div
                class="flex_box flex_justify_between flex_align_center onenumber"
              >
                <div>已熟记 {{ item.remember_count }}/{{ item.count }}</div>
                <div>{{ item.percent.toFixed(2) }}%</div>
              </div>
              <div class="goon" @click="toList(item.id,item.title)">继续背单词</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
var _this;
export default {
  components: {
    headers,
    footers,
    left,
    right,
    scrollpage,
  },
  data() {
    return {
      words: [],
    };
  },
  created() {
    _this = this;
    _this.getList();
  },
  methods: {
    // 获取单词分类列表
    getList() {
      _this.$axios
        .post("word.word_book/lis", {})
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.words = res.data;
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 跳转单词列表
    toList(id,title) {
      _this.$router.push({
        path: "/wordList",
        query: {
          id: id,
          title: title,
        },
      });
    },
  },
};
</script>


<style>
.wordone {
  width: 210px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 3px 12px #ebd0c3;
  opacity: 1;
  border-radius: 10px;
  padding: 21px;
  box-sizing: border-box;
  margin-right: 22px;
  margin-bottom: 22px;
}
.wordone img {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}
.wordone .onetitle {
  font-weight: 600;
  font-size: 16px;
}
.wordone .progress {
  margin: 17px 0;
}
.wordone .onenumber {
  font-style: 14px;
  color: #8c9198;
}
.wordone .goon {
  width: 118px;
  height: 34px;
  margin: 0 auto;
  border: 1px solid #ef654f;
  opacity: 1;
  border-radius: 22px;
  font-style: 14px;
  text-align: center;
  line-height: 34px;
  color: #ef654f;
  margin-top: 37px;
  cursor: pointer;
}
.fenlei {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 600;
  line-height: 44px;
  color: #b90038;
  margin: 44px 0;
}
.fenlei img {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
</style>
