<template>
    <div class="container">
        <div class="footer_wrap">
            <div class="footer_box">
                <div class="footer_top flex_box flex_align_center box_sizing">
                    <!-- <div class="footer_top_list">
                        <div class="footer_top_list_title">PTE练题&模考</div>
                        <div class="footer_top_item_box flex_box flex_align_center justify-start">
                            <div class="footer_top_item">听力</div>
                            <div class="footer_top_item">口语</div>
                            <div class="footer_top_item">阅读</div>
                            <div class="footer_top_item">写作</div>
                        </div>
                        <div class="footer_top_item_box flex_box flex_align_center justify-start">
                            <div class="footer_top_item">PTE模考</div>
                            <div class="footer_top_item">移动端练习</div>
                            <div class="footer_top_item">口语分提前测</div>
                        </div>
                    </div> -->
                    <div class="footer_top_line" style="margin: 0 42px 0 0;"></div>
                    <div class="footer_top_list">
                        <div class="footer_top_list_title">PTE易王炸</div>
                        <div class="footer_top_item_box flex_box flex_align_center justify-start">
                            <div class="footer_top_item">PTE初见</div>
                            <!-- <div class="footer_top_item">PTE课程</div> -->
                        </div>
                        <div class="footer_top_item_box flex_box flex_align_center justify-start">
                            <div class="footer_top_item">PTE攻略</div>
                            <div class="footer_top_item">RA朗读者</div>
                        </div>
                    </div>
                    <div class="footer_top_line"></div>
                    <div class="footer_top_list">
                        <div class="footer_top_list_title">免费留学</div>
                        <div class="footer_top_item_box flex_box flex_align_center justify-start">
                            <div class="footer_top_item">英国留学</div>
                            <div class="footer_top_item">澳洲留学</div>
                            <div class="footer_top_item">加拿大留学</div>
                            <div class="footer_top_item">新西兰留学</div>
                            <div class="footer_top_item">爱尔兰留学</div>
                        </div>
                        <div class="footer_top_item_box flex_box flex_align_center justify-start">
                            <div class="footer_top_item">校园推荐</div>
                            <div class="footer_top_item">专业介绍</div>
                            <div class="footer_top_item">留学成功案例</div>
                            <div class="footer_top_item">学长学姐分享</div>
                            <div class="footer_top_item">留学答疑FAQ</div>
                        </div>
                    </div>
                    <div class="footer_top_line"></div>
                    <div class="footer_top_list">
                        <div class="footer_top_list_title">关于我们</div>
                        <div class="footer_top_item_box flex_box flex_align_center justify-start">
                            <div class="footer_top_item">企业简介</div>
                        </div>
                        <div class="footer_top_item_box flex_box flex_align_center justify-start">
                            <div class="footer_top_item">明星老师</div>
                        </div>
                    </div>
                    <div class="footer_talk">与我们交谈</div>
                </div>
                <div class="footer_bottom">
                    <div class="footer_top_list">
                        <div class="footer_top_list_title">联系</div>
                        <div class="footer_top_item_box flex_box flex_align_center justify-start">
                            <div class="footer_top_item">地址：福建省厦门市集美区杏林湾营运中心9号楼1401单元</div>
                            <div class="footer_top_item">联系电话：15960388308</div>
                            <div class="footer_top_item">邮箱：ruby@mischools.net</div>
                        </div>
                        <div class="footer_top_item_box flex_box flex_align_center justify-start">
                            <div class="footer_top_item">易吉申（厦门）教育科技有限公司 2020© 版权所有 闽ICP备2020019516号-1</div>
                        </div>
                    </div>
                    <div class="qrcode">
                        <img src="https://yijishentest.brofirst.cn/assets/img/qrcode.jpg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'footers',
    data() {
        return {
            isLogin: false,
            text: '',
            name: '用户名称',
            time: '2020.09.22'
        }
    },
    methods: {

    }
}
</script>

<style>
    .footer_wrap {
        min-width: 1400px;
        width: 100%;
        /* height: 400px; */
        background: rgba(229, 92, 40, 1);
        /* position: fixed;
        bottom: 0;
        left: 0; */
    }

    .footer_box {
        width: 1406px;
        margin: 0 auto;
    }

    .footer_top {
        width: 100%;
        height: 206px;
        padding: 54px 0 40px;
        border-bottom: 1px solid  rgba(255, 150, 110, 1);
    }

    .footer_top_item_box {
        margin-bottom: 20px;
    }

    .footer_top_list_title {
        font-size: 16px;
        color: rgba(255, 255, 255, 1);
        line-height: 1;
        margin-bottom: 28px;
    }

    .footer_top_item {
        font-size: 14px;
        color: rgba(255, 255, 255, 1);
        margin-right: 26px;
    }

    .footer_top_line {
        width: 1px;
        height: 112px;
        background: rgba(255, 150, 110, 1);
        margin: 0 42px;
    }

    .footer_talk {
        width: 164px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        font-size: 16px;
        color: rgba(229, 92, 40, 1);
    }

    .footer_bottom {
        width: 100%;
        padding: 58px 0;
        display: flex;
        justify-content: space-between;
    }
    .qrcode img{
        width: 400px;
        height: 150px;
    }
</style>
