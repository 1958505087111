<template>
    <div class="container">
      <headers></headers>
      <right></right>
      <scrollpage></scrollpage>
  
      <div class="w box_sizing width_box my_wrap">
        <div class="left_position">
          <left></left>
        </div>
  
        <div class="question_list_wrap box_sizing">
  
          <div
            class="question_type_box box_sizing flex_box flex_align_center flex_justify_between"
          >
          </div>
          <div v-if="list.length>0" class="question_list_box">
  
            <div
              class="question_list box_sizing flex_box flex_align_center flex_justify_between"
            >
              <div
                class="question_list_info box_sizing flex_box flex_align_center"
                style="justify-content: space-between;"
              >
                <!-- <div class="question_list_tihao">{{ item.topic_type.short_name }}</div> -->
                <div class="exam_title title_color">考试名称</div>
                <div class="exam_title title_color">考试状态</div>
                <div class="exam_title title_color">考试时间</div>
                <div class="exam_title title_color">操作</div>
              </div>
            </div>
            <div
              class="question_list box_sizing flex_box flex_align_center flex_justify_between"
              v-for="(item, index) in filteredList"
              :key="index"
            >
              <div
                class="question_list_info box_sizing flex_box flex_align_center"
                style="justify-content: space-between;"
              >
                <!-- <div class="question_list_tihao">{{ item.topic_type.short_name }}</div> -->
                <div class="exam_title">{{ item.exam_title }}</div>
                <div class="exam_title">{{ item.status == 1 ? '进行中' : '已结束' }}</div>
                <div class="exam_title">{{ item.createtime }}</div>
                <div class="exam_title" v-if="item.status == 1" @click="toExamDetail(item.id)"><div class="view_score">继续作答</div></div>
                <div class="exam_title" v-if="item.status == 2" @click="toExamScore(item.id)"><div class="view_score">查看分数</div></div>
              </div>
            </div>
  
  
          </div>
          <div v-else style="height: 400px;width: 100%;display: flex;align-items: center;justify-content: center;flex-direction: column">
            <img src="@/assets/image/empty.png" style="width: 120px;height: 120px">
            <span style="color: #c4cad3">暂无数据</span>
          </div>
          <div style="display: flex;  justify-content: flex-end;">
            <el-pagination
              :page-size="currentPage.rows"
              layout="prev, pager, next"
              :total="currentPage.total"
              :current-page="currentPage.page"
              @current-change="pageChange"
            />
          </div>
        </div>
      </div>
      <footers></footers>
    </div>
  
  </template>
  
  <script>
  import headers from "@/components/headers";
  import footers from "@/components/footders";
  import left from "@/components/left";
  import right from "@/components/right";
  import scrollpage from "@/components/scrollpage";
  import "@/css/questionList.css";
  
  var _this;
  export default {
    components: {
      headers,
      footers,
      left,
      right,
      scrollpage,
    },
    computed: {
      filteredList() {
        // 根据特定条件过滤数组，例如 status 为 2 的项
        // return this.list.filter(item => item.status == 2);
        return this.list;
      }
    },
    data() {
      return {
        page:"",
        currentPage:    {
          page:  1,
          rows:  10,
          total: 0
        },
        list:           [],
        count:          0,
        exercise_count: 0,
        allList:        [],
      };
    },
    created() {
      _this = this;
    },
    mounted(){
        this.currentPage = {
          page:  1,
          rows:  10,
          total: 0
        };
        this.getList();
    },
    methods:  {
      toExamDetail(id){
        _this.$router.push({
          path: './mokaoDetail',
          query: {
            user_exam_id: id
          }
        });
      },
      toExamScore(id){
        _this.$router.push({
          path: './mokaoScore',
          query: {
            user_exam_id: id
          }
        });
      },
      pageChange(e) {
        this.currentPage.page = e
        console.log('当前额', _this.currentPage)
  
        _this.getList()
      },
      // 获取模考列表
      getList() {
        var list = [];
        var params = {
          // topic_type_id: _this.id,
          page: _this.currentPage.page,
          rows: _this.currentPage.rows
        };
        _this.$axios
          .post("exam.user_exam/getList", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
                console.log('res',res);
              res.data.list.data.forEach((item, index) => {
                list.push(item);
              });
              _this.allList = res.data.list.data;
              _this.list = list;
              _this.currentPage.page = res.data.list.current_page;
              _this.currentPage.total = res.data.list.total;
              _this.count = res.data.list.total;
              _this.exercise_count = res.data.list.total;
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
  </script>
  
  
  <style scoped>
  .clear_img {
    position: absolute;
    left: 60px;
    top: 5px;
    width: 16px;
    height: 16px;
  }
  
  .pointer {
    cursor: pointer;
    user-select: none;
  }
  
  .color_black {
    color: black !important;
  }
  
  .question_lian_cishu {
    padding: 5px 14px;
    margin-right: 12px;
    background: #FFFFFF;
    border-radius: 4px;
    font-size: 12px;
    color: #f6704b;
    border: 1px solid #f6704b;
  }
  .exam_title {
    width: 25%;
    text-align: center;
    flex: 0 0 auto;
    color: #06121E;
  }
  .title_color {
    font-size: 16px;
    color: #f6704b;
  }
  .view_score {
    width: 80px;
    cursor: pointer;
    background: #eeeeee;
    padding: 6px 0;
    border-radius: 6px;
    margin: 0 auto;
  }
  </style>
  