<template>
    <div class="container">
      <headers></headers>
      <right></right>
      <scrollpage></scrollpage>
  
      <div class="w box_sizing width_box my_wrap">
        <div class="left_position">
          <left></left>
        </div>
        <div class="vue-echarts-example">
          <v-chart class="chart" :option="option" />
        </div>
      </div>
      <footers></footers>
    </div>
  </template>
  
  <script>
  import headers from "@/components/headers";
  import footers from "@/components/footders";
  import left from "@/components/left";
  import right from "@/components/right";
  import scrollpage from "@/components/scrollpage";
  import "@/css/pagePing.css";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart,LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
} from "echarts/components";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
]);
  
  var _this;
  export default {
    components: {
      headers,
      footers,
      left,
      right,
      scrollpage
    },
    data() {
      return {
        current: 0,
        mokaoId: [],
        id: "",
        name: "",
        user_exam_id: "",
        detail: {},
        content: '',
        // 图表配置
        option: {
          title: {
            text: 'communicative skills'
          },
          tooltip: {},
          legend: {
            data: ['分数', '平均分']
          },
          xAxis: {
            type: 'value',
            name: '分数',
            lineStyle: {
              color: '#000' // X轴线颜色为黑色
            }
          },
          yAxis: {
            type: 'category',
            name: '',
            data: ['listening', 'reading', 'speaking', 'writing']
          },
          series: [
            {
              name: '分数',
              type: 'bar',
              barWidth: '40%', // 柱子宽度
              data: [0, 0, 0, 0],
              itemStyle: {
                normal: {
                  color: '#f6704b' // 柱子颜色为 #f6704b
                }
              }
            },
            {
              name: '平均分',
              type: 'line',
              markLine: {
                data: [
                  { type: 'average', name: '平均分' } // 平均分线，显示平均值
                ]
              },
              data: [0, 0, 0, 0],  // 平均分数据，可以从后台获取或者动态计算
              itemStyle: {
                normal: {
                  color: '#f6704b' // 柱子颜色为 #f6704b
                }
              }
            }
          ]
        }
      };
    },
    created() {
      _this = this;
        _this.getParams();
    },
    mounted() {
    },
    methods: {
        // 获取传递过来的参数
        getParams() {
        _this.user_exam_id = _this.$route.query.user_exam_id;
        _this.getScore();
        },
        // 获取模考结果
        getScore() {
        var params = {
            user_exam_id: _this.user_exam_id,
        };
        _this.$axios
            .post("exam.user_exam/score", params)
            .then((res) => {
            console.log(res);
            if (res.code == 1) {
                _this.option.series[0].data = [
                  res.data.listening,
                  res.data.reading,
                  res.data.speaking,
                  res.data.writing
                ];
                let average = ((res.data.listening+res.data.reading+res.data.speaking+res.data.writing)/4).toFixed(2)
                _this.option.series[1].data = [average,average,average,average]
            } else {
                _this.$message.error(res.msg);
            }
            })
            .catch((err) => {
            console.log(err);
            });
        },
        // 返回上一页
        goBack() {
          _this.$router.go(-2);
        },
    },
  };
  </script>
  
  
  <style>
.vue-echarts-example {
  width: 90%;
  height: 400px;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
  </style>
  