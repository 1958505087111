<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>

    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>

      <div class="banner_detail box_sizing">
        <!-- <div
          class="listTitle flex_box flex_justify_between flex_align_center box_sizing"
        >
          <div class="flex_box flex_align_center">
            <div class="biao">{{name}}</div> -->
            <!-- <div class="number">总体数：330</div>
            <div class="number">已练习：220</div> -->
          <!-- </div>
          <img src="../assets/images/guanbi.png" alt="" style="width: 16px" />
        </div> -->
        <!-- <div class="flex_box flex_align_center box_sizing statusList">
          <div
            class="statusone"
            :class="current == 0 ? 'activeone' : ''"
            @click="current = 0"
          >
            按更新
          </div>
          <div
            class="statusone"
            :class="current == 1 ? 'activeone' : ''"
            @click="current = 1"
          >
            按完成人数
          </div>
          <div
            class="statusone"
            :class="current == 2 ? 'activeone' : ''"
            @click="current = 2"
          >
            只看未做
          </div>
        </div> -->
        <div
          class="flex_box flex_align_center flex_justify_between mokaoone box_sizing"
          @click="toProduce(item.id)"
          v-for="(item, index) in list"
          :key="index"
        >
          <div>{{item.title}}</div>
          <div class="flex_box flex_align_center people">
            <div>完成人数：{{item.num}}人</div>
            <div>{{item.createtime}}</div>
          </div>
        </div>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
var _this;
export default {
  components: {
    headers,
    footers,
    left,
    right,
    scrollpage,
  },
  data() {
    return {
      current: 0,
      mokao: [1, 1, 1, 1],
      list: [],
      id: "",
      name: ''
    };
  },
  created() {
    _this = this;
    _this.getParams();
  },
  mounted() {},
  methods: {
    // 获取试卷列表
    getList() {
        var params = {
            type: _this.id,
        };
        _this.$axios
            .post("exam.exam/getList", params)
            .then((res) => {
                console.log('模考试卷列表：：：：：：',res);
                if (res.code == 1) {
                    _this.list = res.data;
                } else {
                    _this.$message.error(res.msg);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    },

    // 获取传递过来的参数
    getParams() {
        _this.id = _this.$route.query.id;
        _this.name = _this.$route.query.name;
        _this.getList();
    },

    // 跳转模考介绍
    toProduce(id) {
      if(!localStorage.getItem("token")) {
        this.$message.error("请登录后再进行答题");return
      } else {
        _this.$router.push({
            path: './produce',
            query: {
                id: id,
                name: _this.name
            }
        })
      }
    },
  },
};
</script>


<style>
.banner_detail {
  width: 100%;
  padding: 34px 35px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 1);
}
.listTitle {
  width: 951px;
  height: auto;
  padding: 11px 20px;
  background: #f9f9f9;
  opacity: 1;
}
.biao {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 30px;
  color: #06121e;
  margin-right: 20px;
}
.number {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 30px;
  color: #8c9198;
  margin-right: 20px;
}
.statusList {
  padding: 28px 0;
}
.statusone {
  width: 111px;
  height: 30px;
  border: 1px solid #8c9198;
  opacity: 1;
  border-radius: 4px;
  text-align: center;
  line-height: 30px;
  margin-right: 32px;
  color: #8c9198;
}
.activeone {
  color: #f6704b;
  border: 1px solid #f6704b;
}
.mokaoone {
  font-size: 14px;
  font-weight: 500;
  padding: 20px;
  color: #06121e;
  border-bottom: 1px solid #f7f8fa;
}
.people {
  font-size: 12px;
}
.people div {
  margin-left: 20px;
  font-weight: 500;
}
</style>
