<script>
import Navtitle from "@/components/navtitle.vue";
import Topicinfo from "@/components/topicinfo.vue";
import Mp3Tool from "@/components/mp3Tool.vue";


export default ({
  components: {
    Navtitle,
    Topicinfo,
    Mp3Tool
  },
  data() {
    return {
      topic_id:     "",
      exericise_id: "",
      pinyin:       "",
      title:        "",
      page:         "",
      detail:       {},
      modolStatus:  false,

    }
  },
  mounted() {
    let params = this.$route.query
    console.log('当前的参数', params)
    let {topic_id, exericise_id, pinyin, title, page} = params
    this.topic_id = topic_id;
    this.exericise_id = exericise_id;
    this.pinyin = pinyin;
    this.title = title;
    this.page = page;
    this.getExerciseDetail()
  },
  methods: {
    getExerciseDetail() {
      let _this = this
      var params = {
        topic_exercise_id: this.exericise_id
      };

      this.$axios
        .post("exercise.topic_exercise/getExerciseDetail", params)
        .then((res) => {
          if (res.code == 1) {
            _this.detail = res.data
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    seeDaFen() {
      this.modolStatus = true
    }
  }
})
</script>

<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>

    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>

      <div class="question_list_wrap box_sizing">

        <div class="question_type_box box_sizing flex_box flex_align_center flex_justify_between">
          <navtitle :ischoose="3" :page="page" :pinyin="pinyin" :title="title" :topic_id="topic_id"></navtitle>
        </div>
        <div class="topic_wrap">
          <topicinfo :topic-info="detail.topic" :topic-type-info="detail.topic_type"></topicinfo>
          <!--          答案专区-->
          <!--          <div class="topic_type_answer_box box_sizing" style="min-height: 280px">-->


          <!--          </div>-->
          <!--操作专区-->
          <div class="topic_type_answer_bottom box_sizing flex_box flex_align_center flex_justify_between">
            <div class="topic_type_answer_botcenter box_sizing flex_box flex_align_center">
              <div class="topic_type_answer_botcenter_list box_sizing flex_box flex_align_center" @click="seeDaFen()">
                <img alt="" class="topic_type_answer_botcenter_img" src="../assets/images/dafen_icon.png" />
                <div class="topic_type_answer_botcenter_txt">查看答案及打分</div>
              </div>
              <div>作答时间：{{detail.createtime}}</div>
            </div>
          </div>
        </div>
        <!--大作文和总结全文-->
        <div v-if="modolStatus &&  (detail.topic_type_id === 6 || detail.topic_type_id === 7 || detail.topic_type_id === 23)" class="model">
          <div class="modelContent box_sizing">
            <div class="title">{{ title }}</div>
            <div class="flex-warp flex_box onebox flex_justify_between ">
              <div v-if="detail.extend.content_score" class="one flex_box flex_align_center box_sizing flex-wrap">
                <div class="neirong flex_box">内容</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.content_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.content_score.full }}分
                  </div>
                </div>
              </div>

              <div v-if="detail.extend.format_score" class="one flex_box flex_align_center box_sizing">
                <div class="neirong flex_box">格式</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.format_score.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.format_score.full }}分</div>
                </div>
              </div>
              <div v-if="detail.extend.grammar" class="one flex_box flex_align_center box_sizing">
                <div class="neirong flex_box">语法</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.grammar.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.grammar.full }}分</div>
                </div>
              </div>
              <div v-if="detail.extend.wordScore" class="one flex_box flex_align_center box_sizing">
                <div class="neirong flex_box">词汇范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.wordScore.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.wordScore.full }}分</div>
                </div>
              </div>
              <div v-if="detail.extend.spelling" class="one flex_box flex_align_center box_sizing">
                <div class="neirong flex_box">拼写</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.spelling.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.spelling.full }}分</div>
                </div>
              </div>


              <div v-if="detail.extend.structure" class="one flex_box flex_align_center box_sizing">
                <div class="neirong flex_box">发展结构</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div v-if="detail.extend.email" class="one flex_box flex_align_center box_sizing">
                <div class="neirong flex_box">邮件格式</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.email.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.email.full }}分</div>
                </div>
              </div>
              <div v-if="detail.extend.struct_score" class="one flex_box flex_align_center box_sizing">
                <div class="neirong flex_box">语言范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.struct_score.struct_score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.struct_score.full }}分</div>
                </div>
              </div>
              <div v-if="detail.extend.total" class="one flex_box flex_align_center box_sizing">
                <div class="neirong flex_box">总分</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.total.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.total.full }}分</div>
                </div>
              </div>

            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">作答内容</div>
              <div class="zuodacont" style="height: 200px !important;" v-html="detail.extend.content"></div>
            </div>

            <div class="cancel" @click="modolStatus = false">
              <img alt="" src="../assets/images/login_close.png" />
            </div>
          </div>
        </div>

        <!--阅读单选-->
        <div v-if="modolStatus && detail.topic_type_id === 8" class="model">
          <div class="modelContent box_sizing">
            <div class="title">阅读单选</div>
            <div class="flex-warp flex_box onebox flex_justify_between">
              <div
                v-if="detail.extend.content_score"
                class="one flex_box flex_align_center box_sizing flex-wrap"
              >
                <div class="neirong flex_box">内容</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.content_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.content_score.full }}分
                  </div>
                </div>
              </div>
              <div
                v-if="detail.extend.format_score"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">格式</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.format_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.format_score.full }}分
                  </div>
                </div>
              </div>
              <div
                v-if="detail.extend.structure"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">发展结构</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.grammar"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">语法</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.grammar.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.grammar.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.structure"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">语言范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.wordScore"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">词汇范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.wordScore.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.wordScore.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.spelling"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">拼写</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.spelling.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.spelling.full }}分</div>
                </div>
              </div>
            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">作答内容</div>
              <div class="zuodacont">{{ detail.extend.content }}</div>
              <div class="zuodatitle box_sizing">正确答案</div>
              <div class="zuodacont">{{ detail.extend.daan }}</div>
            </div>

            <div class="cancel" @click="modolStatus = false">
              <img alt="" src="../assets/images/login_close.png" />
            </div>

          </div>
        </div>
        <!--阅读多选-->
        <div v-if="modolStatus  && detail.topic_type_id === 9" class="model">
          <div class="modelContent box_sizing">
            <div class="title">阅读多选</div>
            <div class="flex-warp flex_box onebox flex_justify_between">
              <div
                v-if="detail.extend.content_score"
                class="one flex_box flex_align_center box_sizing flex-wrap"
              >
                <div class="neirong flex_box">内容</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.content_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.content_score.full }}分
                  </div>
                </div>
              </div>
              <div
                v-if="detail.extend.format_score"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">格式</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.format_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.format_score.full }}分
                  </div>
                </div>
              </div>
              <div
                v-if="detail.extend.structure"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">发展结构</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.grammar"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">语法</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.grammar.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.grammar.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.structure"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">语言范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.wordScore"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">词汇范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.wordScore.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.wordScore.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.spelling"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">拼写</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.spelling.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.spelling.full }}分</div>
                </div>
              </div>
            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">作答内容</div>
              <div class="zuodacont">{{ detail.extend.content }}</div>
            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">正确答案</div>
              <div class="zuodacont">{{ detail.extend.daan }}</div>
            </div>
            <div class="cancel" @click="modolStatus = false">
              <img alt="" src="../assets/images/login_close.png" />
            </div>
          </div>
        </div>

        <!--todo 段落排序-->

        <div class="model" v-if="modolStatus  && detail.topic_type_id === 10">
          <div class="modelContent box_sizing">
            <div class="title">{{ detail.topic_type_name }}</div>
            <div class="flex-warp flex_box onebox flex_justify_between">
              <div
                class="one flex_box flex_align_center box_sizing flex-wrap"
                v-if="detail.extend.content_score"
              >
                <div class="neirong flex_box">内容</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.content_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.content_score.full }}分
                  </div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.format_score"
              >
                <div class="neirong flex_box">格式</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.format_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.format_score.full }}分
                  </div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.structure"
              >
                <div class="neirong flex_box">发展结构</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.grammar"
              >
                <div class="neirong flex_box">语法</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.grammar.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.grammar.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.structure"
              >
                <div class="neirong flex_box">语言范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.wordScore"
              >
                <div class="neirong flex_box">词汇范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.wordScore.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.wordScore.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.spelling"
              >
                <div class="neirong flex_box">拼写</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.spelling.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.spelling.full }}分</div>
                </div>
              </div>

              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.score || detail.extend.score == 0"
              >
                <div class="fenshu">
                  <div>得分：{{ detail.extend.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.full }}分</div>
                </div>
              </div>
            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">作答内容</div>
              <div class="" style="margin-bottom: 50px; width: 100%">
                <div v-for="(item, index) in detail.extend.content" :key="index">
                  【{{ index + 1 }}】{{ item ? item : "空" }} &nbsp;
                </div>
              </div>
            </div>
            <div class="cancel" @click="modolStatus = false">
              <img src="../assets/images/login_close.png" alt="" />
            </div>

          </div>
        </div>


        <!--        阅读填空-->


        <div class="model" v-if="modolStatus && detail.topic_type_id === 11">
          <div class="modelContent box_sizing">
            <div class="title">{{ detail.topic_type_name }}</div>
            <div class="flex-warp flex_box onebox flex_justify_between">
              <div
                class="one flex_box flex_align_center box_sizing flex-wrap"
                v-if="detail.extend.content_score"
              >
                <div class="neirong flex_box">内容</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.content_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.content_score.full }}分
                  </div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.format_score"
              >
                <div class="neirong flex_box">格式</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.format_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.format_score.full }}分
                  </div>
                </div>
              </div>
              <div class="one flex_box flex_align_center box_sizing" v-if="detail.extend.structure">
                <div class="neirong flex_box">发展结构</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.grammar"
              >
                <div class="neirong flex_box">语法</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.grammar.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.grammar.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.structure"
              >
                <div class="neirong flex_box">语言范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.wordScore"
              >
                <div class="neirong flex_box">词汇范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.wordScore.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.wordScore.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.spelling"
              >
                <div class="neirong flex_box">拼写</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.spelling.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.spelling.full }}分</div>
                </div>
              </div>
              <div class="one flex_box flex_align_center box_sizing" v-if="detail.extend.score || detail.extend.score == 0">
                <div class="fenshu">
                  <div>得分：{{ detail.extend.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.full }}分</div>
                </div>
              </div>
            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">作答内容</div>
              <div class="zuodacont">

                <div>你的答案：{{ Object.values(detail.extend.content).join(", ") }}</div>
                <div>正确答案：{{ Object.keys(detail.extend.content).join(", ") }}</div>
              </div>
            </div>

            <div class="cancel" @click="modolStatus = false">
              <img src="../assets/images/login_close.png" alt="" />
            </div>

          </div>
        </div>
        <!--阅读写作填空-->
        <div v-if="modolStatus && detail.topic_type_id === 12" class="model">
          <div class="modelContent box_sizing">
            <div class="title">{{ title }}</div>
            <div class="flex-warp flex_box onebox flex_justify_between">
              <div
                v-if="detail.extend.content_score"
                class="one flex_box flex_align_center box_sizing flex-wrap"
              >
                <div class="neirong flex_box">内容</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.content_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.content_score.full }}分
                  </div>
                </div>
              </div>
              <div
                v-if="detail.extend.format_score"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">格式</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.format_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.format_score.full }}分
                  </div>
                </div>
              </div>
              <div
                v-if="detail.extend.structure"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">发展结构</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.grammar"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">语法</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.grammar.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.grammar.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.structure"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">语言范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.wordScore"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">词汇范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.wordScore.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.wordScore.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.spelling"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">拼写</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.spelling.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.spelling.full }}分</div>
                </div>
              </div>
            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">作答内容</div>
              <div class="zuodacont">
                <div>{{ Object.values(detail.extend.content).join(", ") }}</div>
              </div>
              <div class="zuodatitle box_sizing">正确答案</div>
              <div class="zuodacont">
                <div>{{ Object.keys(detail.extend.content).join(", ") }}</div>
              </div>
            </div>

            <div class="cancel" @click="modolStatus = false">
              <img alt="" src="../assets/images/login_close.png" />
            </div>
          </div>
        </div>


        <!--听写句子和录音总结-->
        <div class="model" v-if="modolStatus && ( detail.topic_type_id === 13 || detail.topic_type_id === 20 )">
          <div class="modelContent box_sizing">
            <div class="title">{{ detail.topic_type_name }}</div>
            <div class="flex-warp flex_box onebox flex_justify_between">
              <div class="one flex_box flex_align_center box_sizing flex-wrap" v-if="detail.extend.content_score">
                <div class="neirong flex_box">内容</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.content_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.content_score.full }}分
                  </div>
                </div>
              </div>
              <div class="one flex_box flex_align_center box_sizing" v-if="detail.extend.format_score">
                <div class="neirong flex_box">格式</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.format_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.format_score.full }}分
                  </div>
                </div>
              </div>
              <div class="one flex_box flex_align_center box_sizing" v-if="detail.extend.structure">
                <div class="neirong flex_box">发展结构</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div class="one flex_box flex_align_center box_sizing" v-if="detail.extend.grammar">
                <div class="neirong flex_box">语法</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.grammar.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.grammar.full }}分</div>
                </div>
              </div>
              <div class="one flex_box flex_align_center box_sizing" v-if="detail.extend.structure">
                <div class="neirong flex_box">语言范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div class="one flex_box flex_align_center box_sizing" v-if="detail.extend.wordScore">
                <div class="neirong flex_box">词汇范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.wordScore.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.wordScore.full }}分</div>
                </div>
              </div>
              <div class="one flex_box flex_align_center box_sizing" v-if="detail.extend.spelling">
                <div class="neirong flex_box">拼写</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.spelling.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.spelling.full }}分</div>
                </div>
              </div>
              <div class="one flex_box flex_align_center box_sizing" v-if="detail.extend.score || detail.extend.score == 0">
                <div class="neirong flex_box">内容</div>
                <div class="fenshu" style="text-align: left">
                  <div>得分：{{ detail.extend.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.full }}分</div>
                </div>
              </div>
            </div>

            <div class="zuoda box_sizing" v-if="detail.topic_type_id == 20">
              <div class="">
                <div class="zuodatitle box_sizing">作答内容</div>

                <div class="" style="display: flex;flex-wrap: wrap;">

                  <div v-for="(item, index) in detail.extend.word_state_arr" :key="index">
                    <div class="text" :class="[
                    item.state == 0
                      ? (item.word == ',' || item.word == '.' ? 'error cancel_margin' : 'error')
                      : item.state == 1
                      ? (item.word == ',' || item.word == '.' ? 'success cancel_margin' : 'success')
                      : (item.word == ',' || item.word == '.' ? 'omit cancel_margin' : 'omit'),
                  ]"
                         style="margin-right: 3px"
                    >
                      {{ item.word }}
                    </div>
                  </div>
                </div>
                <div class="zuodatitle box_sizing">正确答案</div>
                <div class="words">{{ detail.extend.answer }}</div>
              </div>

            </div>
            <div class="zuoda box_sizing" v-else>
              <div class="zuodatitle box_sizing">作答内容</div>
              <div class="zuodacont" style="height: 200px !important;" v-html="detail.extend.content"></div>
            </div>

            <div class="cancel" @click="modolStatus = false">
              <img src="../assets/images/login_close.png" alt="" />
            </div>

          </div>
        </div>
        <!--识别错词-->

        <div class="model" v-if="modolStatus && detail.topic_type_id === 19">
          <div class="modelContent box_sizing">
            <div class="title">识别错词</div>
            <div class="flex-warp flex_box onebox flex_justify_between">
              <div
                class="one flex_box flex_align_center box_sizing flex-wrap"
                v-if="detail.extend.content_score"
              >
                <div class="neirong flex_box">内容</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.content_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.content_score.full }}分
                  </div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.format_score"
              >
                <div class="neirong flex_box">格式</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.format_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.format_score.full }}分
                  </div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.structure"
              >
                <div class="neirong flex_box">发展结构</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.grammar"
              >
                <div class="neirong flex_box">语法</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.grammar.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.grammar.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.structure"
              >
                <div class="neirong flex_box">语言范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.wordScore"
              >
                <div class="neirong flex_box">词汇范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.wordScore.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.wordScore.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.spelling"
              >
                <div class="neirong flex_box">拼写</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.spelling.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.spelling.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.score || detail.extend.score >= 0"
              >
                <div class="fenshu">
                  <div>得分：{{ detail.extend.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.full }}分</div>
                </div>
              </div>
            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">作答内容</div>
              <div class="zuodacont">{{ detail.extend.content }}</div>
            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">正确答案</div>
              <div class="zuodacont" style="margin-bottom: 50px; display: flex">
                <div
                  v-for="(item, index) in detail.extend.return"
                  :key="index"
                  style="margin-right: 10px"
                >
                  {{ index }}
                </div>
              </div>
            </div>

            <div class="cancel" @click="modolStatus = false">
              <img src="../assets/images/login_close.png" alt="" />
            </div>
          </div>
        </div>

        <!--补全对话+听力单选+摘要选择-->

        <div class="model" v-if="modolStatus && (detail.topic_type_id === 16 || detail.topic_type_id === 17 || detail.topic_type_id === 18)">
          <div class="modelContent box_sizing">
            <div class="title">{{ detail.topic_type_name }}</div>
            <div class="flex-warp flex_box onebox flex_justify_between">
              <div class="one flex_box flex_align_center box_sizing flex-wrap">
                <div class="neirong flex_box">内容</div>
                <div class="fenshu">
                  <div>得分：{{  detail.extend.score }}分</div>
                  <div style="color: #8c9198">满分：{{  detail.extend.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if=" detail.extend.format_score"
              >
                <div class="neirong flex_box">格式</div>
                <div class="fenshu">
                  <div>得分：{{  detail.extend.format_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{  detail.extend.format_score.full }}分
                  </div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if=" detail.extend.structure"
              >
                <div class="neirong flex_box">发展结构</div>
                <div class="fenshu">
                  <div>得分：{{  detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{  detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if=" detail.extend.grammar"
              >
                <div class="neirong flex_box">语法</div>
                <div class="fenshu">
                  <div>得分：{{  detail.extend.grammar.score }}分</div>
                  <div style="color: #8c9198">满分：{{  detail.extend.grammar.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if=" detail.extend.structure"
              >
                <div class="neirong flex_box">语言范围</div>
                <div class="fenshu">
                  <div>得分：{{  detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{  detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if=" detail.extend.wordScore"
              >
                <div class="neirong flex_box">词汇范围</div>
                <div class="fenshu">
                  <div>得分：{{  detail.extend.wordScore.score }}分</div>
                  <div style="color: #8c9198">满分：{{  detail.extend.wordScore.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if=" detail.extend.spelling"
              >
                <div class="neirong flex_box">拼写</div>
                <div class="fenshu">
                  <div>得分：{{  detail.extend.spelling.score }}分</div>
                  <div style="color: #8c9198">满分：{{  detail.extend.spelling.full }}分</div>
                </div>
              </div>
            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">作答内容</div>
              <div class="zuodacont">{{  detail.extend.content }}</div>
            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">正确答案</div>
              <div class="zuodacont">{{ detail.extend.daan }}</div>
            </div>

            <div class="cancel" @click="modolStatus = false">
              <img src="../assets/images/login_close.png" alt="" />
            </div>
          </div>
        </div>
        <!--听力填空-->
        <div v-if="modolStatus && detail.topic_type_id === 15" class="model">
          <div class="modelContent box_sizing">
            <div class="title">{{ detail.topic_type_name }}</div>
            <div class="flex-warp flex_box onebox flex_justify_between">
              <div v-if="detail.extend.content_score" class="one flex_box flex_align_center box_sizing flex-wrap">
                <div class="neirong flex_box">内容</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.content_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.content_score.full }}分
                  </div>
                </div>
              </div>
              <div v-if="detail.extend.format_score" class="one flex_box flex_align_center box_sizing">
                <div class="neirong flex_box">格式</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.format_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.format_score.full }}分
                  </div>
                </div>
              </div>
              <div v-if="detail.extend.structure" class="one flex_box flex_align_center box_sizing">
                <div class="neirong flex_box">发展结构</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div v-if="detail.extend.grammar" class="one flex_box flex_align_center box_sizing">
                <div class="neirong flex_box">语法</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.grammar.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.grammar.full }}分</div>
                </div>
              </div>
              <div v-if="detail.extend.structure" class="one flex_box flex_align_center box_sizing">
                <div class="neirong flex_box">语言范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div v-if="detail.extend.wordScore" class="one flex_box flex_align_center box_sizing">
                <div class="neirong flex_box">词汇范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.wordScore.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.wordScore.full }}分</div>
                </div>
              </div>
              <div v-if="detail.extend.spelling" class="one flex_box flex_align_center box_sizing">
                <div class="neirong flex_box">拼写</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.spelling.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.spelling.full }}分</div>
                </div>
              </div>
              <div v-if="detail.extend.score || detail.extend.score >= 0" class="one flex_box flex_align_center box_sizing">
                <div class="fenshu">
                  <div>得分：{{ detail.extend.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.full }}分</div>
                </div>
              </div>
            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">作答内容</div>
              <div class="zuodacont" style="margin-bottom: 50px">
                <!--            <div v-for="(item,index) in detail.extend.content" :key="index" >-->
                <!--              {{ index }} - {{ item ? item : "空" }} &nbsp;-->
                <div>你的答案：{{ Object.values(detail.extend.content).join(", ") }}</div>
                <div>正确答案：{{ Object.keys(detail.extend.content).join(", ") }}</div>
                <!--            </div>-->
              </div>
            </div>

            <div class="cancel" @click="modolStatus = false">
              <img alt="" src="../assets/images/login_close.png" />
            </div>

          </div>
        </div>
        <!--听力多选-->
        <div class="model" v-if="modolStatus && detail.topic_type_id === 14" >
          <div class="modelContent box_sizing">
            <div class="title">{{ detail.topic_type_name }}</div>
            <div class="flex-warp flex_box onebox flex_justify_between">
              <div
                class="one flex_box flex_align_center box_sizing flex-wrap"
                v-if="detail.extend.content_score"
              >
                <div class="neirong flex_box">内容</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.content_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.content_score.full }}分
                  </div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.format_score"
              >
                <div class="neirong flex_box">格式</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.format_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.format_score.full }}分
                  </div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.structure"
              >
                <div class="neirong flex_box">发展结构</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.grammar"
              >
                <div class="neirong flex_box">语法</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.grammar.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.grammar.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.structure"
              >
                <div class="neirong flex_box">语言范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.wordScore"
              >
                <div class="neirong flex_box">词汇范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.wordScore.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.wordScore.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.spelling"
              >
                <div class="neirong flex_box">拼写</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.spelling.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.spelling.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.score || detail.extend.score == 0"
              >
                <div class="neirong flex_box">内容</div>
                <div class="fenshu" style="text-align: left">
                  <div>得分：{{ detail.extend.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.full }}分</div>
                </div>
              </div>
            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">作答内容</div>
              <div class="zuodacont">{{ detail.extend.content }}</div>
              <div class="zuodatitle box_sizing">正确答案</div>
              <div class="zuodacont">{{ detail.extend.daan }}</div>
            </div>

            <div class="cancel" @click="modolStatus = false">
              <img src="../assets/images/login_close.png" alt="" />
            </div>
          </div>
        </div>


        <!--  复述句子+-->
        <div v-if="modolStatus && (detail.topic_type_id === 2 ||
         detail.topic_type_id === 4 ||
         detail.topic_type_id === 5 ||
         detail.topic_type_id === 22 )" class="model">
          <div class="modelContent box_sizing">
            <div class="title">{{ detail.topic_type_name }}</div>

            <div class="flex-warp flex_box onebox flex_justify_between">
              <div
                class="one flex_box flex_align_center box_sizing flex-wrap"
                v-if="detail.extend.content_score"
              >
                <div class="neirong flex_box">内容</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.content_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.content_score.full }}分
                  </div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.quality_score"
              >
                <div class="neirong flex_box">发音分</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.quality_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.quality_score.full }}分
                  </div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.pte_score"
              >
                <div class="neirong flex_box">流利度</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.pte_score.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.pte_score.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.format_score"
              >
                <div class="neirong flex_box">格式</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.format_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.format_score.full }}分
                  </div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.structure"
              >
                <div class="neirong flex_box">发展结构</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.grammar"
              >
                <div class="neirong flex_box">语法</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.grammar.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.grammar.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.structure"
              >
                <div class="neirong flex_box">语言范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.wordScore"
              >
                <div class="neirong flex_box">词汇范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.wordScore.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.wordScore.full }}分</div>
                </div>
              </div>
              <div
                class="one flex_box flex_align_center box_sizing"
                v-if="detail.extend.spelling"
              >
                <div class="neirong flex_box">拼写</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.spelling.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.spelling.full }}分</div>
                </div>
              </div>
            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">作答内容</div>
              <mp3-tool
                ref="mp3"
                :src-url="detail.extend.content"
              ></mp3-tool>
            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">AI识别</div>
              <div class="ai_box">
                <div class="ai_level_type">
                  <div class="ai_level_type_color goodcolor"></div>
                  <div>Good</div>
                </div>
                <div class="ai_level_type">
                  <div class="ai_level_type_color badcolor"></div>
                  <div>Bad</div>
                </div>
                <div class="ai_level_type">
                  <div class="ai_level_type_color averagecolor"></div>
                  <div>Average</div>
                </div>
              </div>
              <div v-html="detail.extend.ai_result"></div>
            </div>

            <div class="cancel" @click="modolStatus = false">
              <img src="../assets/images/login_close.png" alt="" />
            </div>
          </div>
        </div>

        <!--描述图片-->
        <div v-if="modolStatus && detail.topic_type_id === 3" class="model">
          <div class="modelContent box_sizing">
            <div class="title">描述图片</div>

            <div class="flex-warp flex_box onebox flex_justify_between">
              <div
                v-if="detail.extend.content_score"
                class="one flex_box flex_align_center box_sizing flex-wrap"
                style="margin-right: 6px"
              >
                <div class="neirong flex_box">内容</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.content_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.content_score.full }}分
                  </div>
                </div>
              </div>
              <div
                v-if="detail.extend.quality_score"
                class="one flex_box flex_align_center box_sizing"
                style="margin-right: 6px"
              >
                <div class="neirong flex_box">发音分</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.quality_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.quality_score.full }}分
                  </div>
                </div>
              </div>
              <div
                v-if="detail.extend.pte_score"
                class="one flex_box flex_align_center box_sizing"
                style="margin-right: 6px"
              >
                <div class="neirong flex_box">流利度</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.pte_score.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.pte_score.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.format_score"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">格式</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.format_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.format_score.full }}分
                  </div>
                </div>
              </div>
              <div
                v-if="detail.extend.structure"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">发展结构</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.grammar"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">语法</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.grammar.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.grammar.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.structure"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">语言范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.wordScore"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">词汇范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.wordScore.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.wordScore.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.spelling"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">拼写</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.spelling.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.spelling.full }}分</div>
                </div>
              </div>
            </div>
            <div class="zuoda box_sizing">

              <mp3-tool
                ref="mp3"
                :src-url="detail.extend.content"
              ></mp3-tool>
            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">AI识别</div>
              <div class="ai_box">
                <div class="ai_level_type">
                  <div class="ai_level_type_color goodcolor"></div>
                  <div>Good</div>
                </div>
                <div class="ai_level_type">
                  <div class="ai_level_type_color badcolor"></div>
                  <div>Bad</div>
                </div>
                <div class="ai_level_type">
                  <div class="ai_level_type_color averagecolor"></div>
                  <div>Average</div>
                </div>
              </div>
              <div v-html="detail.extend.ai_result"></div>
            </div>

            <div class="cancel" @click="modolStatus = false">
              <img alt="" src="../assets/images/login_close.png" />
            </div>

          </div>
        </div>


        <!--朗读句子-->
        <div v-if="modolStatus && detail.topic_type_id === 1" class="model">
          <div class="modelContent box_sizing">
            <div class="title">Read Aloud</div>
            <div class="flex-warp flex_box onebox flex_justify_between">
              <div
                v-if="detail.extend.content_score"
                class="one flex_box flex_align_center box_sizing flex-wrap"
              >
                <div class="neirong flex_box">内容</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.content_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.content_score.full }}分
                  </div>
                </div>
              </div>
              <div
                v-if="detail.extend.quality_score"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">发音分</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.quality_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.quality_score.full }}分
                  </div>
                </div>
              </div>
              <div
                v-if="detail.extend.pte_score"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">流利度</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.pte_score.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.pte_score.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.format_score"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">格式</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.format_score.score }}分</div>
                  <div style="color: #8c9198">
                    满分：{{ detail.extend.format_score.full }}分
                  </div>
                </div>
              </div>
              <div
                v-if="detail.extend.structure"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">发展结构</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.grammar"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">语法</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.grammar.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.grammar.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.structure"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">语言范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.structure.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.wordScore"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">词汇范围</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.wordScore.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.wordScore.full }}分</div>
                </div>
              </div>
              <div
                v-if="detail.extend.spelling"
                class="one flex_box flex_align_center box_sizing"
              >
                <div class="neirong flex_box">拼写</div>
                <div class="fenshu">
                  <div>得分：{{ detail.extend.spelling.score }}分</div>
                  <div style="color: #8c9198">满分：{{ detail.extend.spelling.full }}分</div>
                </div>
              </div>
            </div>
            <div class="zuoda" style="font-size: 16px;" v-if="detail.word_data != null">
              <div>{{detail.word_data}}</div>
            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">作答内容</div>
              <!--          <input-->
              <!--            type="button"-->
              <!--            class="btn-record-voice"-->
              <!--            @click="playAudio"-->
              <!--            v-model="bofangTxt"-->
              <!--          />-->
              <mp3-tool
                ref="mp3"
                :src-url="detail.extend.content"
              ></mp3-tool>
            </div>
            <div class="zuoda box_sizing">
              <div class="zuodatitle box_sizing">AI识别</div>
              <div class="ai_box">
                <div class="ai_level_type">
                  <div class="ai_level_type_color goodcolor"></div>
                  <div>Good</div>
                </div>
                <div class="ai_level_type">
                  <div class="ai_level_type_color badcolor"></div>
                  <div>Bad</div>
                </div>
                <div class="ai_level_type">
                  <div class="ai_level_type_color averagecolor"></div>
                  <div>Average</div>
                </div>
              </div>
              <div v-html="detail.extend.ai_result"></div>
            </div>

            <div class="cancel" @click="modolStatus = false">
              <img alt="" src="../assets/images/login_close.png" />
            </div>
          </div>
        </div>


      </div>
    </div>

    <footers></footers>

  </div>
</template>

<style scoped>

</style>
