<template>
    <div class="container">
        <el-dialog :center="true" title="" :visible.sync="dialogTableVisible" @close="close">
            <div class="word_title">
                {{ word_data.word }}
            </div>
            <div class="word_yb">
                <div class="word_yb_list">
                    <div>UK</div>
                    <div class="word_mar">{{ word_data.symUk }}</div>
                    <div><img src="@/assets/image/bofang.png" class="word_audio" @click="play(1)"></div>
                    <audio ref="audio" :src="word_data.symUkSoundLink"></audio>
                </div>
                <div class="word_yb_list">
                    <div>US</div>
                    <div class="word_mar">{{ word_data.symUs }}</div>
                    <div><img src="@/assets/image/bofang.png" class="word_audio" @click="play(2)"></div>
                    <audio ref="audio2" :src="word_data.symUsSoundLink"></audio>
                </div>
            </div>
            <el-divider></el-divider>
            <div class="word_content">
                <div class="word_container">
                    <div class="word_row_title">词典释义</div>
                    <div>
                        <div v-for="(item, index) in word_data.means" :key="index">
                            <div class="word_means_list">{{ item.m }}</div>
                        </div>
                    </div>
                </div>
                <div class="word_container">
                    <div class="word_row_title">参考例句</div>
                    <div>
                        <div v-for="(item, index) in word_data.sentences" :key="index">
                            <div class="word_means_list">{{ item.eg }}</div>
                            <div class="word_means_list">{{ item.ch }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex">
                <div @click="dialogTableVisible = false" class="word_cancel">返回</div>
                <div @click="add" class="add_word" v-if="is_book == 0">加入单词本</div>
                <div class="add_complete" v-else>已添加</div>
            </div>
        </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    name: "word",
    data() {
      return {
        dialogTableVisible:false,
        word_text: '',
        is_book: 0,
        word_data: {}
      };
    },
    created() {
    },
    methods: {
        // 获取学习时长
        getWord() {
            this.$axios.post("word.word_book/get_word",{
                word: this.word_text
            }).then((res) => {
                if (res.code == 1) {
                    console.log('res',res);
                    if(res.data.data.dict == null) {
                        this.$message.error("词典中未找到该单词");
                        this.dialogTableVisible=false;
                    } else {
                        this.dialogTableVisible=true;
                        this.word_data = res.data.data.dict;
                        this.is_book = res.data.data.is_book
                        console.log('this.word_data',this.word_data);
                    }
                } else {
                this.$message.error(res.msg);
                }
            });
        },
        close(){
            this.word_text = ''
            this.word_data = {}
        },
        play(type){
            if(type == 1) {
                this.$refs.audio.play(); //提示音效
            }
            if(type == 2) {
                this.$refs.audio2.play(); //提示音效
            }
        },
        add(){
            var params = {
                word: this.word_text
                .toLowerCase()
                .replace(/[^\u4e00-\u9fa5\w]/g, "")
                .trim(),
            };
            this.$axios
                .post("word.user_word_book/add", params)
                .then((res) => {
                console.log(res);
                if (res.code == 1) {
                    this.$message.success("加入单词本成功");
                    this.is_book = 1;
                } else {
                    this.$message.error(res.msg);
                }
                })
                .catch((err) => {
                    console.log(err);
                    this.$message.error(err.msg);
                });
        }
    },
  };
  </script>
  
  <style>
  .dialog_box {
    width: 90%;
    max-width:1000px;
  }
  .word_title {
    color: #f6704b;
    font-size: 30px;
  }
  .add_complete {
    color: #f6704b;
    font-size: 16px;
    cursor: pointer;
  }
  .word_yb {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .word_yb_list {
    display: flex;
    width: 50%;
    align-items: center;
  }
  .word_mar {
    margin: 0 8px;
  }
  .word_audio {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .word_content {
    display: flex;
    flex-direction: row;
    margin: 20px 0;
    height: 300px;
    overflow: auto;
  }
  .word_container {
    width: 50%;
  }
  .word_row_title {
    font-size: 16px;
    color: #999999;
  }
  .word_means_list {
    font-size: 16px;
    line-height: 30px;
    margin: 8px 0;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    margin: 10px 20px;
  }
  .word_cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #999999;
    color: #999999;
    width: 120px;
    line-height:40px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 2px;
  }
  .add_word {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #f6704b;
    color: #ffffff;
    background: #f6704b;
    width: 120px;
    line-height:40px;
    border-radius: 25px;
    cursor: pointer;
  }
  </style>
  
  
  
  
  
  
  
  
  