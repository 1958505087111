<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>
    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>
      <div class="question_list_wrap box_sizing">
        <!-- 提示音 -->
        <audio ref="audio" src="../../assets/yinxiao.mp3"></audio>
        <div class="question_type_box box_sizing flex_box flex_align_center flex_justify_between"
             v-if="detail.topic_type_id == 2">
          <navtitle :topic_id="id" title="复述句子" pinyin="RS" page="questionType9"></navtitle>
        </div>
        <div class="question_type_box box_sizing flex_box flex_align_center flex_justify_between"
             v-if="detail.topic_type_id == 4">
          <navtitle :topic_id="id" title="复述讲座" pinyin="RL" page="questionType9"></navtitle>
        </div>
        <div class="question_type_box box_sizing flex_box flex_align_center flex_justify_between"
             v-if="detail.topic_type_id == 5">
          <navtitle :topic_id="id" title="短问答" pinyin="AQS" page="questionType9"></navtitle>
        </div>
        <div class="question_type_box box_sizing flex_box flex_align_center flex_justify_between"
             v-if="detail.topic_type_id == 22">
          <navtitle :topic_id="id" title="情景描述" pinyin="RTS" page="questionType9"></navtitle>
        </div>
        <div class="topic_wrap" v-loading="loading" :element-loading-text="loading_text">
          <div class="topic_type_name topic_type_name_asq">
            {{ detail.name }}
          </div>
          <div class="topic_type_info box_sizing flex_box flex_align_center">
            <div class="topic_type_info_time">{{ detail.createtime_text }}</div>
            <div class="question_lian_shoucang">
              <img class="question_list_jiangjieimg" v-if="!detail.collect || detail.collect == 1" @click="collowCilck()"
                src="../../assets/images/shoucang1.png" alt="" />
              <img class="question_list_jiangjieimg" v-if="detail.collect == 2" @click="collowCilck()" src="../../assets/images/shoucang2.png"
                alt="" />
              <img class="question_list_jiangjieimg" v-if="detail.collect == 3" @click="collowCilck()" src="../../assets/images/shoucang3.png"
                alt="" />
              <img class="question_list_jiangjieimg" v-if="detail.collect == 4" @click="collowCilck()" src="../../assets/images/shoucang4.png"
                alt="" />
              <img class="question_list_jiangjieimg" v-if="detail.collect == 5" @click="collowCilck()" src="../../assets/images/shoucang5.png"
                alt="" />
              <div class="question_list_sc_box" v-if="fav_show">
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(1)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang1.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(2)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang2.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(3)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang3.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(4)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang4.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(5)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang5.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- <img src="../../assets/images/type_fenxiang.png" class="topic_type_info_fenxiang" @click="copyDomain()"
                 alt=""/> -->
          </div>
          <div class="topic_type_title">{{ detail.describe }}</div>
          <!-- 题目区域 -->
          <div class="my_padding box_sizing">
            <div class="audio_inp flex_box">
              <div class="audio_left flex_box">
                <img @click="playAudioTopic" v-if="playStatus" :src="zantingUrl"/>
                <img @click="playAudioTopic" v-else :src="playUrl"/>
                <div style="display: flex;align-items: center;margin-left: 10px;">
                  <el-select size="mini" v-model="playbackRate" placeholder="请选择" @change="changePlaybackRate">
                    <el-option
                      v-for="item in playbackRates"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="audio_l_right box_sizing flex_box flex_align_center">
                  <div class="audio_lr_jindu flex_box">
                    <div class="topic_type1_luyin my_xiahuaxian" @click="timeupdate()" @touchstart="touchstart()" @touchmove="touchmove()"
                         @touchend="touchend()">
                      <div class="topic_type1_luyin1" :style="{ width: scrollerwidth }"></div>
                    </div>
                    <div class="my_time box_sizing">{{ timeLength }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img class="my_quest3" v-if="detail.image" :src="detail.image"/>
          <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-if="daojishiTime > 0">
            {{ daojishiTime }}s开始答题
          </div>
          <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-else>
            答题时间：{{ datiTime }}
          </div>
          <div class="topic_type_answer_box box_sizing" style="min-height: 280px">
            <div class="topic_type_answer_title">Recorded Answer</div>
            <div class="topic_type_tt">Curret Status</div>
            <div id="siri-classic" style="width: 100%; height: 100px"></div>

            <audio-cmponent ref="AudioC" @GetAudioData="GetAudioDataEven" @BeginEven="BeginEvenF" @play="playEvent"
              @notplay="notplayEvent" @ErrorMsg="ErrorMsg" @audioDuration="audioDuration"></audio-cmponent>
          </div>
          <div class="topic_type_answer_bottom box_sizing flex_box flex_align_center flex_justify_between">
            <div class="topic_type_answer_botleft box_sizing flex_box flex_align_center">
              <div class="topic_type_answer_botbtn" @click="submitTopic">
                完成
              </div>
              <div class="topic_type_answer_botbtn now" @click="chongzuo">
                重做
              </div>
              <div class="">
                <span style="margin-right: 3px">答案：</span>
                <el-tooltip content="解析答案">
                  <el-switch v-model="showSwitch" active-color="#f6704b"></el-switch>
                </el-tooltip>
              </div>
              <div v-if="detail.extend.is_switch" style="margin-left: 20px">
                <span style="margin-right: 3px">查看原文：</span>
                <el-tooltip content="查看原文">
                  <el-switch v-model="showContent" active-color="#f6704b"></el-switch>
                </el-tooltip>
              </div>
            </div>
            <div class="topic_type_answer_botcenter box_sizing flex_box flex_align_center">
              <div @click="seeDaFen" class="topic_type_answer_botcenter_list box_sizing flex_box flex_align_center">
                <img src="../../assets/images/dafen_icon.png" class="topic_type_answer_botcenter_img" alt=""/>
                <div class="topic_type_answer_botcenter_txt">查看打分</div>
              </div>
              <div @click="shenQingPiGai"
                   class="topic_type_answer_botcenter_list box_sizing flex_box flex_align_center">
                <img src="../../assets/images/shenqing_icon.png" class="topic_type_answer_botcenter_img" alt=""/>
                <div class="topic_type_answer_botcenter_txt">申请批改</div>
              </div>
            </div>
            <div class="topic_type_answer_botright box_sizing flex_box flex_align_center">
              <div class="topic_type_answer_botbtn tihao" @click="changeTopic('lastTopic')">上一题</div>
              <div class="topic_type_answer_botbtn" @click="changeTopic('nextTopic')">
                下一题
              </div>
            </div>
          </div>
          <div v-show="showSwitch" class="common_content">
            <div v-html="detail.show_tape_text"></div>
          </div>
          <div v-if="showContent" class="common_content">
            <div v-html="detail.extend.content"></div>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
    <!-- 弹框 -->
    <div class="model" v-if="status">
      <div class="modelContent box_sizing">
        <div class="title">{{ detail.topic_type_name }}</div>
        <div class="flex-warp flex_box onebox" v-if="!result_loading">
          <div class="one flex_box flex_align_center box_sizing flex-wrap" v-if="obj.content_score">
            <div class="neirong flex_box">内容</div>
            <div class="fenshu">
              <div>得分：{{ obj.content_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.content_score.full }}分
              </div>
            </div>
          </div>
          <div class="one flex_box flex_align_center box_sizing" v-if="obj.quality_score">
            <div class="neirong flex_box">发音分</div>
            <div class="fenshu">
              <div>得分：{{ obj.quality_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.quality_score.full }}分
              </div>
            </div>
          </div>
          <div class="one flex_box flex_align_center box_sizing" v-if="obj.pte_score">
            <div class="neirong flex_box">流利度</div>
            <div class="fenshu">
              <div>得分：{{ obj.pte_score.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.pte_score.full }}分</div>
            </div>
          </div>
          <div class="one flex_box flex_align_center box_sizing" v-if="obj.format_score">
            <div class="neirong flex_box">格式</div>
            <div class="fenshu">
              <div>得分：{{ obj.format_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.format_score.full }}分
              </div>
            </div>
          </div>
          <div class="one flex_box flex_align_center box_sizing" v-if="obj.structure">
            <div class="neirong flex_box">发展结构</div>
            <div class="fenshu">
              <div>得分：{{ obj.structure.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.structure.full }}分</div>
            </div>
          </div>
          <div class="one flex_box flex_align_center box_sizing" v-if="obj.grammar">
            <div class="neirong flex_box">语法</div>
            <div class="fenshu">
              <div>得分：{{ obj.grammar.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.grammar.full }}分</div>
            </div>
          </div>
          <div class="one flex_box flex_align_center box_sizing" v-if="obj.structure">
            <div class="neirong flex_box">语言范围</div>
            <div class="fenshu">
              <div>得分：{{ obj.structure.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.structure.full }}分</div>
            </div>
          </div>
          <div class="one flex_box flex_align_center box_sizing" v-if="obj.wordScore">
            <div class="neirong flex_box">词汇范围</div>
            <div class="fenshu">
              <div>得分：{{ obj.wordScore.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.wordScore.full }}分</div>
            </div>
          </div>
          <div class="one flex_box flex_align_center box_sizing" v-if="obj.spelling">
            <div class="neirong flex_box">拼写</div>
            <div class="fenshu">
              <div>得分：{{ obj.spelling.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.spelling.full }}分</div>
            </div>
          </div>
        </div>
        <div class="waiting_result" v-else>等待AI评分中...</div>
        <div class="zuoda box_sizing">
          <div class="zuodatitle box_sizing">作答内容</div>
          <mp3-tool ref="mp3" :src-url="auFullUrl" @showDuration="getDuration"></mp3-tool>
        </div>
        <div class="zuoda box_sizing" v-if="!result_loading">
          <div class="zuodatitle box_sizing">AI识别</div>
          <div class="ai_box">
            <div class="ai_level_type">
              <div class="ai_level_type_color goodcolor"></div>
              <div>Good</div>
            </div>
            <div class="ai_level_type">
              <div class="ai_level_type_color badcolor"></div>
              <div>Bad</div>
            </div>
            <div class="ai_level_type">
              <div class="ai_level_type_color averagecolor"></div>
              <div>Average</div>
            </div>
          </div>
          <div v-html="obj.ai_result"></div>
        </div>
        <div v-else></div>
        <div class="cancel" @click="status = false">
          <img src="../../assets/images/login_close.png" alt=""/>
        </div>
        <!-- <div class="ping">
                    <img src="../../assets/images/wenhao.png" alt="">
                    <div>评分细则</div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
import SiriWave from "siriwave";
import Mp3Tool from "@/components/mp3Tool.vue";
// 组件
import AudioCmponent from "../../components/audiocmponent/audiocmponent.vue";
import Navtitle from "@/components/navtitle.vue";
let daojishiBo; // 答题倒计时
let waitingresult; // 等待结果
let _this;
export default {
  components: {
    Navtitle,
    headers,
    footers,
    left,
    right,
    scrollpage,
    AudioCmponent,
    Mp3Tool
  },
  data() {
    return {
      topicExerciseId: null,
      classic: undefined,
      showContent: false,
      loading: false,
      loading_text: "正在获取AI评分，请稍后...",
      recordings: false, // 标记是否在录音
      id: "",
      detail: {
        extend: {}
      },
      auUrl: "", // 上传录音文件地址
      auFullUrl: "", // 上传录音文件访问地址
      timeLen: 40,
      obj: {},
      huida: false,
      status: false,
      title: "",
      playStatus: false,
      fav_show: false,
      playUrl: require("../../assets/images/play.png"),
      zantingUrl: require("../../assets/images/zanting_icon@2x.png"),
      timeLength: "00:00:00",
      audio: null,
      timer: null,
      timers: "00:00:00",
      datiTime: "00:00:00",
      result_loading: false,
      timeLens: 0,
      daojishiTime: 3,
      recorderData: {},
      timenum: "", //当前剩余时间
      timeall: "", //音频总长
      LastTime: 0, // 播放时上次的播放进度
      audio_answer_time: null, // 答题录音时间
      // 正在录音标识
      showSwitch: false,
      answer_time: 40,
      playbackRate: 1.0,
      playbackRates: [
        {
          label: 'x2.0',
          value: 2.0
        },
        {
          label: 'x1.5',
          value: 1.5
        },
        {
          label: 'x1.25',
          value: 1.25
        },
        {
          label: 'x1.0',
          value: 1.0
        },
        {
          label: 'x0.75',
          value: 0.75
        },
        {
          label: 'x0.5',
          value: .5
        }
      ]
    };
  },
  created(e) {
    _this = this;
    _this.getParams();
    _this.audio = new Audio();
  },
  destroyed() {
    _this.audio.pause();
    _this.timeLength = _this.timers;
    _this.playStatus = false;
    _this.audio.load();
    clearInterval(_this.timer);
    clearInterval(daojishiBo);
  },
  computed: {
    // 进度条
    scrollerwidth: function () {
      console.log(_this.timenum);
      // 进度条长度计算，当前时间除以总时间乘以百分比
      let a = _this.timenum; //当前剩余时间
      let b = _this.timeall; //音频总长
      var c = ((b - a) / b) * 100 + "%";
      // 保存进度
      _this.LastTime = a;
      return c;
    },
  },
  mounted() {
    console.log();
    _this.init_classic();
  },
  methods: {
    //选择收藏哪个
    collowCilck() {
      _this.fav_show = !_this.fav_show
      console.log('12313',_this.fav_show);
    },
    // 收藏题目
    collectFun(type) {
      // console.log(i)
      let params = {
        topic_id: _this.detail.id,
        type: type,
      };

      if (localStorage.getItem("token")) {
        _this.$axios
          .post("exercise.topic_collect/collect", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              _this.detail.collect = type;
              _this.fav_show = false;
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        _this.$message.error("您还没登录，请先登录");
      }
    },
    init_classic() {
      _this.classic = new SiriWave({
        container: document.getElementById("siri-classic"),
        height: 100,
        autostart: false,
        // 显示比例
        ratio: 2,
        // 动画速度
        speed: 0.1,
        // 振幅
        amplitude: 1,
        // 频率(iOS风格)
        frequency: 6,
        // 曲线颜色 (iOS风格)
        color: "#f56207",
        // 覆盖容器
        cover: false,
        // 在画布上步骤数量
        pixelDepth: 0.02,
        // 插入速度
        lerpSpeed: 0.01,
      });
    },
    copyDomain() {
      if (_this.topicExerciseId == null) {
        this.$message.error("请申请批改后复制链接");
        return;
      }
      _this.$copyText(_this.$global.baseUrl + "&id=" + _this.topicExerciseId);
      this.$message.success("地址复制成功");
    },
    getDuration(time) {
      console.log("time", time);
      _this.timeLen = time;
    },
    playEvent() {
      _this.classic.start();
    },
    notplayEvent() {
      _this.classic.stop();
    },
    timeupdate1(time) {
      // console.log(time,'播放位置')
      // let audio = this.$refs.audio;
      _this.audio.currentTime = time;
    },
    ErrorMsg(){
      _this.$message.error('开始录音失败，请重试')
    },
    // 录音开始
    BeginEvenF() {
      _this.daojishiTime = 0;
      _this.$refs.audio.play(); //提示音效
      _this.classic.start();
      _this.recordings = true;
      console.log("当前录音状态", _this.recordings);
    },
    // 录音结束
    GetAudioDataEven(res) {
      let data = res;
      _this.audio_answer_time = data.duration;
      _this.recorderData = res;
      _this.classic.stop();
      _this.recordings = false;
      _this.submitUp(data.WAV);
    },
    audioDuration(e){
      console.log(e,'eeeeeeeeeeeeeeee');
      _this.audio_answer_time = e.duration; // 实际录音时长
      _this.datiTime = _this.formateSeconds(e.show_time); // 展示录音时长
    },
    // 进入页面倒计时40秒开始录音
    daojishiBack() {
      daojishiBo = setInterval(() => {
        if (_this.daojishiTime <= 0) {
          console.log("倒计时录音状态", _this.recordings);
          if (!_this.recordings && _this.audio_answer_time == null) {
            // _this.$refs.audio.play(); //提示音效
            setTimeout(() => {
              console.log('audioc');
              _this.$refs.AudioC.startRecorder();
            }, 1500);
          }
          clearInterval(daojishiBo);
        } else {
          _this.daojishiTime--;
        }
      }, 1000);
    },
    // 点击播放/暂停
    playAudioTopic() {
      console.log(_this.playStatus, '点击了')
      if (_this.playStatus) {
        _this.audio.pause();
        _this.playStatus = false;
        _this.timeupdate1(_this.detail.duration - _this.LastTime);
        clearInterval(_this.timer);
        // _this.audio.load();
      } else {
        _this.playTopicAudio()
      }
    },
    changePlaybackRate(e) {
      this.audio.playbackRate = this.playbackRate;
    },
    playTopicAudio(){
      console.log('lastTime',_this.LastTime);
      let playPromise,
        second = _this.LastTime && _this.LastTime > 0 ? _this.LastTime : _this.detail.duration;
      playPromise = _this.audio.play();
      _this.playStatus = true;
      if (playPromise) {
        playPromise
          .then(() => {
            // 音频加载成功
            // 音频的播放需要耗时
            _this.audio.playbackRate = _this.playbackRate;
            _this.timer = setInterval(() => {
              console.log(second);
              second = second-1*_this.playbackRate;
              _this.timeLength = _this.formateSeconds(second, 1);
              if (second <= 0) {
                // 播放结束
                _this.audio.load();
                // 重置保存上次播放的时间
                _this.LastTime = 0;
                _this.playStatus = false;
                clearInterval(_this.timer);
              }
            }, 1000);
          })
          .catch((e) => {
            // 音频加载失败
            console.error(e);
          });
      }
    },
    //将秒转化为时分秒1
    formateSeconds(endTime, e) {
      if (e) {
        // console.log('endTime', endTime);
        _this.timenum = endTime
      }
      // _this.timenum = endTime; //????
      let secondTime = parseInt(endTime); //将传入的秒的值转化为Number
      let min = 0; // 初始化分
      let h = 0; // 初始化小时
      let result = "";
      if (secondTime >= 60) {
        //如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
        if (min >= 60) {
          //如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      result = `${h.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
      return result;
    },
    // 上一题/下一题
    changeTopic(name) {
      var params = {
        topic_id: _this.id,
        type: _this.detail.topic_type_id,
        exercise_status: localStorage.getItem('lianxiId'),
        topic_collect: localStorage.getItem('collectId'), //收藏 之前添加传的
        pinlvId: localStorage.getItem('pinlvId'),
        tags: localStorage.getItem('tag_id'),
        topic_lecture_id:
        localStorage.getItem('jiangjieId') == 0 ? undefined : localStorage.getItem('jiangjieId') == 1 ? 1 : 0, //名师讲解id 0=没有讲解
      };
      _this.$axios
        .post("exercise.topic/"+name, params)
        .then((res) => {
          // console.log(res);
          if (res.code == 1) {
            _this.id = res.data.topic_id;
            _this.$router.replace({
              path: "questionType9?id=" + res.data.topic_id + "&name=复述句子",
            });
            location.reload();
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          // console.log(err);
          _this.$message.error(err.msg);
        });
    },
    // 重做
    chongzuo() {
      location.reload();
      return;
    },
    // 申请批改
    shenQingPiGai() {
      if (_this.huida) {
        var params = {
          topic_exercise_id: _this.topicExerciseId,
        };
        _this.$axios
          .post("exercise.topic_exercise/submit", params)
          .then((res) => {
            // console.log(res);
            if (res.code == 1) {
              _this.$message.success("您已提交申请");
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            // console.log(err);
            _this.$message.error(err.msg);
          });
      } else {
        _this.$message.error("请先点击完成以提交答题");
      }
    },
    // 查看打分
    seeDaFen() {
      if (_this.huida) {
        _this.status = true;
      } else {
        _this.$message.error("请先点击完成以提交答题");
      }
    },
    // 微信分享复制链接
    copyToH5() {
      let pinyin = _this.detail.topic_type_id == 2 ? 'RS' : (_this.detail.topic_type_id == 4 ? 'RL' : 'ASQ');
      let tips = pinyin + " " + _this.detail.number + " " + " PTE易王炸AI评分 ";
      this.$copyText(tips + " " + window.location.origin + "/H5Detail?exericise_id=" + _this.topicExerciseId + "&topic_id=" + _this.detail.id + "&pinyin=" + pinyin + "&title=" + encodeURIComponent(_this.detail.name) + "&page=questionType6");
      this.$message.success("地址复制成功")
    },
    // 点击完成
    submitTopic() {
      console.log("当前录音状态", _this.recordings);
      // if(_this.detail.topic_type_id != 5) {
        // _this.loading = true;
      // }
      if (_this.recordings) {
        _this.$message.error("录音中,禁止操作");
        return;
      }
      let params = {
        topic_id: _this.id,
        content: _this.auUrl,
        time: _this.audio_answer_time,
      };
      if (localStorage.getItem("token")) {
        _this.$axios
          .post("exercise.topic/submit", params)
          .then((res) => {
            if (res.code == 1) {
              if(!res.data.content) {
                _this.result_loading = true;
                _this.waiting(res.data);
              }
              _this.topicExerciseId = res.data.topicExerciseId;
              _this.obj = res.data;
              _this.huida = true;
              _this.$message.success("答题已完成");
              _this.status = true;
              _this.loading = false;
            } else {
              _this.loading = false;
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            _this.loading = false;
            _this.$message.error(err.msg);
          });
        // } else {
        //   _this.$message.error("您还没答题，请先进行答题");
        // }
      } else {
        _this.$message.error("您还没登录，请先登录");
      }
    },
    waiting(topic_exercise){
      waitingresult = setInterval(() => {
        let params = {
          topic_exercise_id: topic_exercise.topicExerciseId
        }
        _this.$axios
          .post("exercise.topic/getTopicExerciseResult", params)
          .then((res) => {
            if (res.code == 1) {
              if(res.data) {
                _this.obj = res.data;
                _this.result_loading = false;
                clearInterval(waitingresult);
              }
            }
          })
          .catch((err) => {
            _this.loading = false;
            _this.$message.error(err.msg);
          });
      }, 2000);
    },
    // 上传音频重写
    submitUp(res) {
      var formData = new FormData();
      console.log(formData);
      formData.append("file", res);
      var url = "common/uploadRecord";
      const loading = this.$loading({
        lock: true,
        text: '音频上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      _this.$uploadFile
        .post(url, formData)
        .then(function (res) {
          _this.auUrl = res.data.url;
          _this.auFullUrl = res.data.fullurl;
          _this.$refs.AudioC.audioSrc = _this.auUrl;
          loading.close();
          _this.submitTopic();
        })
        .catch((err) => {
        });
    },
    // 获取传递过来的参数
    getParams() {
      _this.id = _this.$route.query.id;
      _this.getDetail();
    },
    // 获取详情
    getDetail() {
      var params = {
        topic_id: _this.id,
      };
      _this.$axios
        .post("exercise.topic/getTopicInfo", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.detail = res.data;
            _this.audio.src = res.data.file;
            _this.timeall = res.data.duration; //音频总长
            console.log(res.data.duration, "res.data.duration");
            _this.timenum = res.data.duration
            _this.timeLength = _this.formateSeconds(res.data.duration);
            _this.timers = _this.formateSeconds(res.data.duration);
            _this.daojishiTime = res.data.wait_time
            _this.answer_time = res.data.answer_time
            _this.$refs.AudioC.changeTime({answer_time:_this.answer_time,allow_time:_this.answer_time-0.1});
            _this.daojishiBack()
            if (_this.detail.topic_type_id == 2) {
              _this.timeLen = 15;
            } else if (_this.detail.topic_type_id == 5) {
              _this.timeLen = 10;
              _this.loading_text = "正在进行评分，请稍后...";
            }
            _this.playTopicAudio()
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
  }
};
</script>


<style>
.vue-slider-rail {
  background: rgba(238, 238, 238, 1);
}

.vue-slider-process {
  background: rgba(246, 112, 75, 1);
}

.banner_detail {
  width: 100%;
  padding: 32px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 1);
  text-align: center;
}
.el-select {
  width: 80px;
}
.waiting_result {
  font-size: 18px;
  color: #F6704B;
}
</style>
