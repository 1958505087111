import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import CouseDetail from "../views/couseDetail.vue";
import PayFail from "../views/payFail.vue";
import PaySuccess from "../views/paySuccess.vue";
import Test from "../views/test.vue";
import My from "../views/my.vue";
import MyCouse from "../views/myCouse.vue";
import MyChat from "../views/myChat.vue";
import myExam from "../views/myExam.vue";
import myExercise from "../views/myExercise.vue";
import Practice from "../views/practice.vue";
import Sync from "../views/sync.vue";
import QuestionList from "../views/questionList.vue";
import QuestionSearchList from "../views/questionSearchList.vue";
import BannerDetail from "../views/bannerDetail.vue";
import StrategyDetail from "../views/strategyDetail.vue";
import StrategyList from "../views/strategyList.vue";
import QuestionType1 from "../views/practice_questionType/questionType1.vue";
import QuestionType3 from "../views/practice_questionType/questionType3.vue";
import QuestionType4 from "../views/practice_questionType/questionType4.vue";
import QuestionType6 from "../views/practice_questionType/questionType6.vue";
import QuestionType7 from "../views/practice_questionType/questionType7.vue";
import QuestionType8 from "../views/practice_questionType/questionType8.vue";
import QuestionType9 from "../views/practice_questionType/questionType9.vue";
import QuestionType10 from "../views/practice_questionType/questionType10.vue";
import QuestionType11 from "../views/practice_questionType/questionType11.vue";
import QuestionType12 from "../views/practice_questionType/questionType12.vue";
import QuestionType14 from "../views/practice_questionType/questionType14.vue";
import QuestionType15 from "../views/practice_questionType/questionType15.vue";
import QuestionType16 from "../views/practice_questionType/questionType16.vue";
import QuestionType17 from "../views/practice_questionType/questionType17.vue";
import Mokao from "../views/mokao.vue";
import MokaoList from "../views/mokaoList.vue";
import Produce from "../views/produce.vue";
import MokaoDetail from "../views/mokaoType/mokaoDetail.vue";
import MokaoScore from "../views/mokaoType/mokaoScore.vue";
import Words from "../views/words.vue";
import WordList from "../views/wordList.vue";
import myDetail from "@/views/myDetail.vue";
import H5Detail from "@/views/H5Detail.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/couseDetail",
      name: "couseDetail",
      component: CouseDetail
    },
    {
      path: "/payFail",
      name: "payFail",
      component: PayFail
    },
    {
      path: "/paySuccess",
      name: "paySuccess",
      component: PaySuccess
    },
    {
      path: "/my",
      name: "my",
      component: My
    },
    {
      path: "/test",
      name: "test",
      component: Test
    },
    {
      path: "/myCouse",
      name: "myCouse",
      component: MyCouse
    },
    {
      path: "/myChat",
      name: "myChat",
      component: MyChat
    },
    {
      path: "/myExam",
      name: "myExam",
      component: myExam
    },
    {
      path: "/myExercise",
      name: "myExercise",
      component: myExercise
    },
    {
      path: "/myDetail",
      name: "myDetail",
      component: myDetail
    },
    {
      path: "/H5Detail",
      name: "H5Detail",
      component: H5Detail
    },
    {
      path: "/practice",
      name: "practice",
      component: Practice
    },
    {
      path: "/sync",
      name: "sync",
      component: Sync
    },
    {
      path: "/questionList",
      name: "questionList",
      component: QuestionList
    },
    {
      path: "/questionSearchList",
      name: "questionSearchList",
      component: QuestionSearchList
    },
    {
      path: "/bannerDetail",
      name: "bannerDetail",
      component: BannerDetail
    },
    {
      path: "/strategyDetail",
      name: "strategyDetail",
      component: StrategyDetail
    },
    {
      path: "/strategyList",
      name: "strategyList",
      component: StrategyList
    },
    {
      path: "/questionType1",
      name: "questionType1",
      component: QuestionType1
    },
    {
      path: "/questionType3",
      name: "questionType3",
      component: QuestionType3
    },
    {
      path: "/questionType4",
      name: "questionType4",
      component: QuestionType4
    },
    {
      path: "/questionType6",
      name: "questionType6",
      component: QuestionType6
    },
    {
      path: "/questionType7",
      name: "questionType7",
      component: QuestionType7
    },
    {
      path: "/questionType8",
      name: "questionType8",
      component: QuestionType8
    },
    {
      path: "/questionType9",
      name: "questionType9",
      component: QuestionType9
    },
    {
      path: "/questionType10",
      name: "questionType10",
      component: QuestionType10
    },
    {
      path: "/questionType11",
      name: "questionType11",
      component: QuestionType11
    },
    {
      path: "/questionType12",
      name: "questionType12",
      component: QuestionType12
    },
    {
      path: "/questionType14",
      name: "questionType14",
      component: QuestionType14
    },
    {
      path: "/questionType15",
      name: "questionType15",
      component: QuestionType15
    },
    {
      path: "/questionType16",
      name: "questionType16",
      component: QuestionType16
    },
    {
      path: "/questionType17",
      name: "questionType17",
      component: QuestionType17
    },
    {
      path: "/mokao",
      name: "mokao",
      component: Mokao
    },
    {
      path: "/mokaoList",
      name: "mokaoList",
      component: MokaoList
    },
    {
      path: "/produce",
      name: "produce",
      component: Produce
    },
    {
      path: "/mokaoDetail",
      name: "mokaoDetail",
      component: MokaoDetail
    },
    {
      path: "/mokaoScore",
      name: "mokaoScore",
      component: MokaoScore
    },
    {
      path: "/words",
      name: "words",
      component: Words
    },
    {
      path: "/wordList",
      name: "wordList",
      component: WordList
    }
  ]
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
};

router.afterEach(() => {
  window.scrollTo(0, 0);
  document
    .querySelector("body")
    ?.setAttribute("style", "overflow: auto !important;");
});
router.beforeEach((to, from, next) => {
  // console.log("重定向参数", to.query.code);
  // let code = to.query.code
  // if (code) {
  //   _this.$axios
  //     .post("wechat/loginCallback", {
  //       code: code
  //     })
  //     .then((res) => {
  //       if (res.data.thirdid) {
  //         _this.thirdid = res.data.thirdid;
  //         _this.wxAuthStatus = true;
  //         // _this.$router.replace({ path: '/WxAuth?thirdid=' + res.data.thirdid, replace: true });
  //       } else {
  //         console.log(res.data.userinfo, "登录");
  //         localStorage.setItem("token", res.data.userinfo.token);
  //         _this.getUser();
  //         // _this.$router.push("/");
  //       }
  //     })
  //     .catch((err) => {
  //       _this.$message.error(err.msg);
  //     });
  // }

  let token = localStorage.getItem("token");
  if (to.path !== "/" && token === null) {
    if (to.path === "/H5Detail") {
      next();
    } else {
      next({ path: "/" });
    }
  } else {
    next();
  }
});

// const router = new VueRouter({
// mode: 'history',
//   routes
// })

export default router;
