<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>
    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>
      <div class="question_list_wrap box_sizing">
        <!-- 提示音 -->
        <audio ref="audio" src="../../assets/yinxiao.mp3"></audio>
        <div class="question_type_box box_sizing flex_box flex_align_center flex_justify_between">
          <navtitle :topic_id="id" title="描述图片" pinyin="DI" page="questionType3"></navtitle>
        </div>
        <div class="topic_wrap" v-loading="loading" element-loading-text="正在获取评分，请稍后...">
          <div class="topic_type_name">{{ detail.name }}</div>
          <div class="topic_type_info box_sizing flex_box flex_align_center">
            <div class="topic_type_info_time">{{ detail.createtime_text }}</div>
            <div class="question_lian_shoucang">
              <img class="question_list_jiangjieimg" v-if="!detail.collect || detail.collect == 1" @click="collowCilck()"
                src="../../assets/images/shoucang1.png" alt="" />
              <img class="question_list_jiangjieimg" v-if="detail.collect == 2" @click="collowCilck()" src="../../assets/images/shoucang2.png"
                alt="" />
              <img class="question_list_jiangjieimg" v-if="detail.collect == 3" @click="collowCilck()" src="../../assets/images/shoucang3.png"
                alt="" />
              <img class="question_list_jiangjieimg" v-if="detail.collect == 4" @click="collowCilck()" src="../../assets/images/shoucang4.png"
                alt="" />
              <img class="question_list_jiangjieimg" v-if="detail.collect == 5" @click="collowCilck()" src="../../assets/images/shoucang5.png"
                alt="" />
              <div class="question_list_sc_box" v-if="fav_show">
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(1)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang1.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(2)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang2.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(3)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang3.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(4)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang4.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(5)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang5.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- <img
              src="../../assets/images/type_fenxiang.png"
              class="topic_type_info_fenxiang"
              alt=""
              @click="copyDomain()"
            /> -->
          </div>
          <div class="topic_type_title">{{ detail.describe }}</div>
          <!-- 题目区域 -->
          <div style="display: flex;">
          <div style="width: 50%;">
            <img class="" style="height: auto;" :src="detail.image" />
          </div>
          <div style="width: 50%;">
            <div
            class="dati_time box_sizing flex_box flex_align_center justify_end" style="margin: 0;"
            v-if="daojishiTime > 0"
          >
            {{ daojishiTime }}s后开始答题
          </div>
          <div
            class="dati_time box_sizing flex_box flex_align_center justify_end" style="margin: 0;"
            v-else
          >
            答题时间：{{ datiTime }}
          </div>
          <div
            class="topic_type_answer_box box_sizing"
            style="min-height: 280px;"
          >
            <div class="topic_type_answer_title">Recorded Answer</div>
            <div class="topic_type_tt">Curret Status</div>
            <div id="siri-classic" style="width: 100%; height: 100px"></div>
            <audio-cmponent
              ref="AudioC"
              @GetAudioData="GetAudioDataEven"
              @BeginEven="BeginEvenF"
              @play="playEvent"
              @notplay="notplayEvent"
              @audioDuration="audioDuration"
            ></audio-cmponent>
          </div>
          </div>
          </div>
          <div
            class="topic_type_answer_bottom box_sizing flex_box flex_align_center flex_justify_between my_bottom3"
          >
            <div
              class="topic_type_answer_botleft box_sizing flex_box flex_align_center"
            >
              <div class="topic_type_answer_botbtn" @click="submitTopic">
                完成
              </div>
              <div class="topic_type_answer_botbtn now" @click="chongzuo">
                重做
              </div>
              <div class="">
                <span style="margin-right: 3px">答案：</span>
                <el-tooltip content="解析答案">
                  <el-switch v-model="showSwitch" active-color="#f6704b"	></el-switch>
                </el-tooltip>
              </div>
            </div>
            <div
              class="topic_type_answer_botcenter box_sizing flex_box flex_align_center"
            >
              <div
                @click="seeDaFen"
                class="topic_type_answer_botcenter_list box_sizing flex_box flex_align_center"
              >
                <img
                  src="../../assets/images/dafen_icon.png"
                  class="topic_type_answer_botcenter_img"
                  alt=""
                />
                <div class="topic_type_answer_botcenter_txt">查看打分</div>
              </div>
              <div
                @click="shenQingPiGai"
                class="topic_type_answer_botcenter_list box_sizing flex_box flex_align_center"
              >
                <img
                  src="../../assets/images/shenqing_icon.png"
                  class="topic_type_answer_botcenter_img"
                  alt=""
                />
                <div class="topic_type_answer_botcenter_txt">申请批改</div>
              </div>
            </div>
            <div
              class="topic_type_answer_botright box_sizing flex_box flex_align_center"
            >
              <div class="topic_type_answer_botbtn tihao" @click="changeTopic('lastTopic')">上一题</div>
              <div class="topic_type_answer_botbtn" @click="changeTopic('nextTopic')">
                下一题
              </div>
            </div>
          </div>
          <div v-show="showSwitch" class="common_content">
            <div v-html="detail.show_tape_text"></div>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
    <!-- 弹框 -->
    <div class="model" v-if="status">
      <div class="modelContent box_sizing">
        <div class="title">描述图片</div>
        <div class="flex-warp flex_box onebox" v-if="!result_loading">
          <div
            class="one flex_box flex_align_center box_sizing flex-wrap"
            v-if="obj.content_score"
            style="margin-right: 6px"
          >
            <div class="neirong flex_box">内容</div>
            <div class="fenshu">
              <div>得分：{{ obj.content_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.content_score.full }}分
              </div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.quality_score"
            style="margin-right: 6px"
          >
            <div class="neirong flex_box">发音分</div>
            <div class="fenshu">
              <div>得分：{{ obj.quality_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.quality_score.full }}分
              </div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.pte_score"
            style="margin-right: 6px"
          >
            <div class="neirong flex_box">流利度</div>
            <div class="fenshu">
              <div>得分：{{ obj.pte_score.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.pte_score.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.format_score"
          >
            <div class="neirong flex_box">格式</div>
            <div class="fenshu">
              <div>得分：{{ obj.format_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.format_score.full }}分
              </div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.structure"
          >
            <div class="neirong flex_box">发展结构</div>
            <div class="fenshu">
              <div>得分：{{ obj.structure.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.structure.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.grammar"
          >
            <div class="neirong flex_box">语法</div>
            <div class="fenshu">
              <div>得分：{{ obj.grammar.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.grammar.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.structure"
          >
            <div class="neirong flex_box">语言范围</div>
            <div class="fenshu">
              <div>得分：{{ obj.structure.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.structure.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.wordScore"
          >
            <div class="neirong flex_box">词汇范围</div>
            <div class="fenshu">
              <div>得分：{{ obj.wordScore.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.wordScore.full }}分</div>
            </div>
          </div>
          <div
            class="one flex_box flex_align_center box_sizing"
            v-if="obj.spelling"
          >
            <div class="neirong flex_box">拼写</div>
            <div class="fenshu">
              <div>得分：{{ obj.spelling.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.spelling.full }}分</div>
            </div>
          </div>
        </div>
        <div class="waiting_result" v-else>等待AI评分中...</div>
        <div class="zuoda box_sizing">
          <mp3-tool
            ref="mp3"
            :src-url="auFullUrl"
            @showDuration="getDuration"
          ></mp3-tool>
        </div>
        <div class="zuoda box_sizing" v-if="!result_loading">
          <div class="zuodatitle box_sizing">AI识别</div>
          <div class="ai_box">
            <div class="ai_level_type">
              <div class="ai_level_type_color goodcolor"></div>
              <div>Good</div>
            </div>
            <div class="ai_level_type">
              <div class="ai_level_type_color badcolor"></div>
              <div>Bad</div>
            </div>
            <div class="ai_level_type">
              <div class="ai_level_type_color averagecolor"></div>
              <div>Average</div>
            </div>
          </div>
          <div v-html="obj.ai_result"></div>
        </div>
        <div class="cancel" @click="status = false">
          <img src="../../assets/images/login_close.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
import "@/css/questionList.css";
import "@/css/questiontype.css";
import "@/css/pagePing.css";
import SiriWave from "siriwave";
import AudioCmponent from "@/components/audiocmponent/audiocmponent.vue";
import Navtitle from "@/components/navtitle.vue";
import Mp3Tool from "@/components/mp3Tool.vue";
// 准备倒计时
var daojishiBo;
let waitingresult; // 等待结果
var _this;
export default {
  components: {
    Navtitle,
    AudioCmponent,
    headers,
    footers,
    left,
    right,
    scrollpage,
    Mp3Tool
  },
  data() {
    return {
      topicExerciseId: null,
      classic: undefined,
      loading: false,
      recordings: false, // 标记是否在录音
      id: "",
      detail: {},
      recorder: null,
      fav_show: false,
      result_loading: false,
      auUrl: "", // 上传录音文件地址
      auFullUrl: "", // 上传录音文件访问地址
      timeLen: 0,
      recorderData: {},
      obj: {},
      huida: false,
      status: false,
      title: "",
      datiTime: "00:00:00",
      daojishiTime: 25,
      audio: null,
      showSwitch: false,
    };
  },
  created() {
    clearInterval(daojishiBo);
    _this = this;
    _this.audio = new Audio();
    _this.getParams();
    _this.daojishiBack();
  },
  mounted() {
    this.init_classic();
  },
  destroyed() {
    _this.timeLen = _this.timeLen;
    _this.audio.pause();
    _this.playStatus = false;
    clearInterval(daojishiBo);
    _this.audio.load();
  },
  methods: {
    //选择收藏哪个
    collowCilck() {
      _this.fav_show = !_this.fav_show
      console.log('12313',_this.fav_show);
    },
    // 收藏题目
    collectFun(type) {
      // console.log(i)
      let params = {
        topic_id: _this.detail.id,
        type: type,
      };

      if (localStorage.getItem("token")) {
        _this.$axios
          .post("exercise.topic_collect/collect", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              _this.detail.collect = type;
              _this.fav_show = false;
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        _this.$message.error("您还没登录，请先登录");
      }
    },
    init_classic() {
      this.classic = new SiriWave({
        container: document.getElementById("siri-classic"),
        height: 100,
        autostart: false,
        // 显示比例
        ratio: 2,
        // 动画速度
        speed: 0.1,
        // 振幅
        amplitude: 1,
        // 频率(iOS风格)
        frequency: 6,
        // 曲线颜色 (iOS风格)
        color: "#f56207",
        // 覆盖容器
        cover: false,
        // 在画布上步骤数量
        pixelDepth: 0.02,
        // 插入速度
        lerpSpeed: 0.01,
      });
    },
    // 录音开始
    BeginEvenF() {
      _this.daojishiTime = 0;
      _this.$refs.audio.play(); //提示音效
      _this.classic.start();
      _this.recordings = true;
      console.log("当前录音状态", _this.recordings);
    },
    // 录音结束
    GetAudioDataEven(res) {
      let data = res;
      _this.timeLen = data.duration;
      _this.recorderData = res;
      _this.classic.stop();
      _this.recordings = false;
      _this.submitUp(data.WAV);
    },
    audioDuration(e){
      console.log(e,'eeeeeeeeeeeeeeee');
      _this.timeLen = e.duration; // 实际录音时长
      _this.datiTime = _this.formateSeconds(e.show_time); // 展示录音时长
    },
    getDuration(time) {
      console.log("time", time);
      _this.timeLen = time;
    },
    playEvent() {
      _this.classic.start();
    },
    notplayEvent() {
      _this.classic.stop();
    },
    copyDomain() {
      if (_this.topicExerciseId == null) {
        this.$message.error("请申请批改后复制链接");
        return;
      }
      _this.$copyText(_this.$global.baseUrl + "&id=" + _this.topicExerciseId);
      this.$message.success("地址复制成功");
    },
    // 进入页面倒计时25秒开始录音
    daojishiBack() {
      daojishiBo = setInterval(() => {
        if (_this.daojishiTime <= 0) {
          clearInterval(daojishiBo);
          // _this.$refs.audio.play(); //提示音效
          setTimeout(() => {
            _this.$refs.AudioC.startRecorder();
          }, 1500);
          let playPromise;
          _this.playStatus = true;
          if (playPromise) {
            playPromise
              .then(() => {
                // 音频加载成功
                // 音频的播放需要耗时
              })
              .catch((e) => {
                // 音频加载失败
                console.error(e);
              });
          }
        } else {
          _this.daojishiTime--;
        }
      }, 1000);
    },
    // 上一题/下一题
    changeTopic(name) {
      var params = {
        topic_id: _this.id,
        type: _this.detail.topic_type_id,
        exercise_status: localStorage.getItem('lianxiId'),
        topic_collect: localStorage.getItem('collectId'), //收藏 之前添加传的
        pinlvId: localStorage.getItem('pinlvId'),
        tags: localStorage.getItem('tag_id'),
        topic_lecture_id:
        localStorage.getItem('jiangjieId') == 0 ? undefined : localStorage.getItem('jiangjieId') == 1 ? 1 : 0, //名师讲解id 0=没有讲解
      };
      _this.$axios
        .post("exercise.topic/"+name, params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            clearInterval(daojishiBo);
            _this.id = res.data.topic_id;
            _this.$router.replace({
              path: "questionType3?id=" + res.data.topic_id + "&name=描述图片",
            });
            location.reload();
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          _this.$message.error(err.msg);
        });
    },
    // 重做
    chongzuo() {
      location.reload();
      return;
    },
    // 申请批改
    shenQingPiGai() {
      if (_this.huida) {
        var params = {
          topic_exercise_id: _this.topicExerciseId,
        };
        _this.$axios
          .post("exercise.topic_exercise/submit", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              _this.$message.success("您已提交申请");
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            _this.$message.error(err.msg);
          });
      } else {
        _this.$message.error("请先点击完成以提交答题");
      }
    },
    // 查看打分
    seeDaFen() {
      if (_this.huida) {
        _this.status = true;
      } else {
        _this.$message.error("请先点击完成以提交答题");
      }
    },
    // 微信分享复制链接
    copyToH5(){
      let pinyin = 'DI';
      let tips = pinyin+" "+_this.detail.number+" "+" PTE易王炸AI评分 ";
      this.$copyText(tips+" "+window.location.origin+"/H5Detail?exericise_id="+_this.topicExerciseId+"&topic_id="+ _this.detail.id+"&pinyin="+pinyin+"&title="+encodeURIComponent(_this.detail.name)+"&page=questionType6");
      this.$message.success("地址复制成功")
    },
    // 点击完成
    submitTopic() {
      console.log("当前录音状态", _this.recordings);
      // _this.loading = true;
      if (_this.recordings) {
        _this.$message.error("录音中,禁止操作");
        return;
      }
      let params = {
        topic_id: _this.id,
        content: _this.auUrl,
        time: _this.timeLen,
      };
      if (localStorage.getItem("token")) {
        _this.$axios
          .post("exercise.topic/submit", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              if(!res.data.content) {
                _this.result_loading = true;
                _this.waiting(res.data);
              }
              _this.obj = res.data;
              _this.topicExerciseId = res.data.topicExerciseId;
              _this.huida = true;
              _this.$message.success("答题已完成");
              _this.status = true;
              _this.loading = false;
            } else {
              _this.loading = false;
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            _this.loading = false;
            console.log(err);
            _this.$message.error(err.msg);
          });
        // }else {
        //     _this.$message.error("您还没答题，请先进行答题");
        // }
      } else {
        _this.$message.error("您还没登录，请先登录");
      }
    },
    waiting(topic_exercise){
      waitingresult = setInterval(() => {
        let params = {
          topic_exercise_id: topic_exercise.topicExerciseId
        }
        _this.$axios
          .post("exercise.topic/getTopicExerciseResult", params)
          .then((res) => {
            if (res.code == 1) {
              if(res.data) {
                _this.obj = res.data;
                _this.result_loading = false;
                clearInterval(waitingresult);
              }
            }
          })
          .catch((err) => {
            _this.loading = false;
            _this.$message.error(err.msg);
          });
      }, 2000);
    },
    // 上传音频
    submitUp() {
      let PCMBlob = _this.recorderData.WAV; //获取 PCM 数据
      console.log(PCMBlob);
      var formData = new FormData();
      formData.append("file", PCMBlob);
      var url = "common/uploadRecord";
      const loading = this.$loading({
        lock: true,
        text: '音频上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      _this.$uploadFile
        .post(url, formData)
        .then(function (res) {
          console.log(res);
          _this.auUrl = res.data.url;
          _this.auFullUrl = res.data.fullurl;
          _this.$refs.AudioC.audioSrc = _this.auUrl;
          loading.close();
          _this.submitTopic();
        })
        .catch((err) => {
          // _this.$notify.error({
          //   title: "提示",
          //   message: "上传失败请重试",
          //   duration: 1500,
          // });
        });
    },
    //将秒转化为时分秒
    formateSeconds(endTime) {
      let secondTime = parseInt(endTime); //将传入的秒的值转化为Number
      let min = 0; // 初始化分
      let h = 0; // 初始化小时
      let result = "";
      if (secondTime >= 60) {
        //如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
        if (min >= 60) {
          //如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      result = `${h.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
      return result;
    },
    // 获取详情
    getDetail() {
      var params = {
        topic_id: _this.id,
      };
      _this.$axios
        .post("exercise.topic/getTopicInfo", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.detail = res.data;
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取传递过来的参数
    getParams() {
      _this.id = _this.$route.query.id;
      _this.title = _this.$route.query.name;
      _this.getDetail();
    },
  },
  beforeDestroy() {
    clearInterval(daojishiBo);
  },
};
</script>
<style>
</style>
