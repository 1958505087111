<script>
import Topicinfo from "@/components/topicinfo.vue";
import Mp3Tool from "@/components/mp3Tool.vue";


export default ({
  components: {
    Topicinfo,
    Mp3Tool
  },
  data() {
    return {
      topic_id: "",
      exericise_id: "",
      pinyin: "",
      title: "",
      page: "",
      detail: {}
    }
  },
  mounted() {
    let params = this.$route.query
    console.log('当前的参数', params)
    let { topic_id, exericise_id, pinyin, title, page } = params
    this.topic_id = topic_id;
    this.exericise_id = exericise_id;
    this.pinyin = pinyin;
    this.title = title;
    this.page = page;
    this.getExerciseDetail()
  },
  methods: {
    getExerciseDetail() {
      let _this = this
      var params = {
        topic_exercise_id: this.exericise_id
      };

      this.$axios
        .post("exercise.topic_exercise/getExerciseDetail", params)
        .then((res) => {
          if (res.code == 1) {
            _this.detail = res.data
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
})
</script>

<template>

  <div class="question_list_wrap_h5 box_sizing">

    <div class="topic_wrap_h5">
      <topicinfo :topic-info="detail.topic" :topic-type-info="detail.topic_type"></topicinfo>
      <div class="flex-warp flex_box onebox">
      <div
        v-if="detail.extend.content_score"
        class="one flex_box flex_align_center box_sizing flex-wrap"
      >
        <div class="neirong flex_box">内容</div>
        <div class="fenshu">
          <div>得分：{{ detail.extend.content_score.score }}分</div>
          <div style="color: #8c9198">
            满分：{{ detail.extend.content_score.full }}分
          </div>
        </div>
      </div>
      <!-- <div class="one flex_box flex_align_center box_sizing" v-if="obj.format_score">
                    <div class="neirong flex_box">格式</div>
                    <div class="fenshu ">
                        <div>得分：{{obj.format_score.score}}分</div>
                        <div style="color: #8C9198;">满分：{{obj.format_score.full}}分</div>
                    </div>
                </div> -->
      <div
        v-if="detail.extend.format_score"
        class="one flex_box flex_align_center box_sizing"
      >
        <div class="neirong flex_box">格式</div>
        <div class="fenshu">
          <div>得分：{{ detail.extend.format_score.score }}分</div>
          <div style="color: #8c9198">满分：{{ detail.extend.format_score.full }}分</div>
        </div>
      </div>
      <div
        class="one flex_box flex_align_center box_sizing"
        v-if="detail.extend.pte_score"
      >
        <div class="neirong flex_box">流利度</div>
        <div class="fenshu">
          <div>得分：{{ detail.extend.pte_score.score }}分</div>
          <div style="color: #8c9198">满分：{{ detail.extend.pte_score.full }}分</div>
        </div>
      </div>
      <div
        class="one flex_box flex_align_center box_sizing"
        v-if="detail.extend.quality_score"
      >
        <div class="neirong flex_box">发音分</div>
        <div class="fenshu">
          <div>得分：{{ detail.extend.quality_score.score }}分</div>
          <div style="color: #8c9198">
            满分：{{ detail.extend.quality_score.full }}分
          </div>
        </div>
      </div>
      <div
        v-if="detail.extend.grammar"
        class="one flex_box flex_align_center box_sizing"
      >
        <div class="neirong flex_box">语法</div>
        <div class="fenshu">
          <div>得分：{{ detail.extend.grammar.score }}分</div>
          <div style="color: #8c9198">满分：{{ detail.extend.grammar.full }}分</div>
        </div>
      </div>
      <!-- <div class="one flex_box flex_align_center box_sizing" v-if="obj.structure">
                    <div class="neirong flex_box">语言范围</div>
                    <div class="fenshu ">
                        <div>得分：{{obj.structure.score}}分</div>
                        <div style="color: #8C9198;">满分：{{obj.structure.full}}分</div>
                    </div>
                </div> -->
      <div
        v-if="detail.extend.wordScore"
        class="one flex_box flex_align_center box_sizing"
      >
        <div class="neirong flex_box">词汇范围</div>
        <div class="fenshu">
          <div>得分：{{ detail.extend.wordScore.score }}分</div>
          <div style="color: #8c9198">满分：{{ detail.extend.wordScore.full }}分</div>
        </div>
      </div>
      <div
        v-if="detail.extend.spelling"
        class="one flex_box flex_align_center box_sizing"
      >
        <div class="neirong flex_box">拼写</div>
        <div class="fenshu">
          <div>得分：{{ detail.extend.spelling.score }}分</div>
          <div style="color: #8c9198">满分：{{ detail.extend.spelling.full }}分</div>
        </div>
      </div>


      <div
        v-if="detail.extend.structure"
        class="one flex_box flex_align_center box_sizing"
      >
        <div class="neirong flex_box">发展结构</div>
        <div class="fenshu">
          <div>得分：{{ detail.extend.structure.score }}分</div>
          <div style="color: #8c9198">满分：{{ detail.extend.structure.full }}分</div>
        </div>
      </div>

      
      <div
        v-if="detail.extend.email"
        class="one flex_box flex_align_center box_sizing"
      >
        <div class="neirong flex_box">邮件格式</div>
        <div class="fenshu">
          <div>得分：{{ detail.extend.email.score }}分</div>
          <div style="color: #8c9198">满分：{{ detail.extend.email.full }}分</div>
        </div>
      </div>


      <div
        v-if="detail.extend.struct_score"
        class="one flex_box flex_align_center box_sizing"
      >
        <div class="neirong flex_box">语言范围</div>
        <div class="fenshu">
          <div>得分：{{ detail.extend.struct_score.struct_score }}分</div>
          <div style="color: #8c9198">满分：{{ detail.extend.struct_score.full }}分</div>
        </div>
      </div>
      <div
        v-if="detail.extend.total"
        class="one flex_box flex_align_center box_sizing"
      >
        <div class="neirong flex_box">总分</div>
        <div class="fenshu">
          <div>得分：{{ detail.extend.total.score }}分</div>
          <div style="color: #8c9198">满分：{{ detail.extend.total.full }}分</div>
        </div>
      </div>

      <!-- <div class="one flex_box flex_align_center box_sizing" v-if="obj.spelling">
                    <div class="neirong flex_box">拼写</div>
                    <div class="fenshu ">
                        <div>得分：{{obj.spelling.score}}分</div>
                        <div style="color: #8C9198;">满分：{{obj.spelling.full}}分</div>
                    </div>
                </div> -->
    </div>
      <!--大作文和总结全文-->
      <div v-if="detail.topic_type_id === 6 || detail.topic_type_id === 7 || detail.topic_type_id === 23">
        <div class="zuodatitle box_sizing">作答内容</div>
        <div v-html="detail.extend.content"></div>
      </div>
      <!-- 阅读单选-->
      <div v-if="detail.topic_type_id === 8">
        <div class="zuodatitle box_sizing">作答内容</div>
        <div class="zuodacont">{{ detail.extend.content }}</div>
        <div class="zuodatitle box_sizing">正确答案</div>
        <div class="zuodacont">{{ detail.extend.daan }}</div>
      </div>
      <!--阅读多选-->
      <div v-if="detail.topic_type_id === 9">
        <div class="zuodatitle box_sizing">作答内容</div>
        <div class="zuodacont">{{ detail.extend.content }}</div>

        <div class="zuodatitle box_sizing">正确答案</div>
        <div class="zuodacont">{{ detail.extend.daan }}</div>
      </div>
      <!-- 阅读填空-->
      <div v-if="detail.topic_type_id === 11">
        <div class="zuodatitle box_sizing">作答内容</div>
        <div>{{ Object.values(detail.extend.content).join(", ") }}</div>
        <div class="zuodatitle box_sizing">正确答案</div>

        <div>{{ Object.keys(detail.extend.content).join(", ") }}</div>
      </div>
      <!--阅读写作填空-->
      <div v-if="detail.topic_type_id === 12">
        <div class="zuodatitle box_sizing">作答内容</div>
        <div>{{ Object.values(detail.extend.content).join(", ") }}</div>
        <div class="zuodatitle box_sizing">正确答案</div>

        <div>{{ Object.keys(detail.extend.content).join(", ") }}</div>
      </div>

      <!--听写句子-->
      <div v-if="detail.topic_type_id === 20 || detail.topic_type_id === 21">
        <div class="">
          <div class="zuodatitle box_sizing">作答内容</div>

          <div class="" style="display: flex;flex-wrap: wrap;">

            <div v-for="(item, index) in detail.extend.word_state_arr" :key="index">
              <div class="text" :class="[
        item.state == 0
          ? (item.word == ',' || item.word == '.' ? 'error cancel_margin' : 'error')
          : item.state == 1
            ? (item.word == ',' || item.word == '.' ? 'success cancel_margin' : 'success')
            : (item.word == ',' || item.word == '.' ? 'omit cancel_margin' : 'omit'),
      ]" style="margin-right: 3px">
                {{ item.word }}
              </div>
            </div>
          </div>
          <div class="zuodatitle box_sizing">正确答案</div>
          <div class="words" v-html="detail.extend.answer"></div>
        </div>
      </div>

      <!--录音总结-->
      <div v-if="detail.topic_type_id === 13">
        <div class="zuodatitle box_sizing">作答内容</div>
        <div v-html="detail.extend.content"></div>
      </div>
      <!--识别错词-->
      <div v-if="detail.topic_type_id === 19">
        <div class="zuodatitle box_sizing">作答内容</div>
        <div class="">{{ detail.extend.content }}</div>
        <div class="zuodatitle box_sizing">正确答案</div>
        <div style="margin-bottom: 50px; display: flex">
          <div v-for="(item, index) in detail.extend.return" :key="index" style="margin-right: 10px">
            {{ index }}
          </div>
        </div>
      </div>

      <!--补全对话+听力单选+摘要选择+听力填空-->
      <div
        v-if="detail.topic_type_id === 16 || detail.topic_type_id === 17 || detail.topic_type_id === 18 || detail.topic_type_id === 14">
        <div class="zuodatitle box_sizing">作答内容</div>
        <div class="">{{ detail.extend.content }}</div>
        <div class="zuodatitle box_sizing">正确答案</div>
        <div class="">{{ detail.extend.daan }}</div>
      </div>
      <!--听力填空-->
      <div v-if="detail.topic_type_id === 15">
        <div class="zuodatitle box_sizing">作答内容</div>
        <div>{{ Object.values(detail.extend.content).join(", ") }}</div>
        <div class="zuodatitle box_sizing">正确答案</div>

        <div>{{ Object.keys(detail.extend.content).join(", ") }}</div>
      </div>
    </div>
    <!--段落排序-->
    <div v-if="detail.topic_type_id === 10">
      <div class="zuodatitle box_sizing">作答内容</div>
      <div class="" style="margin-bottom: 50px; width: 100%">
        <div v-for="(item, index) in detail.extend.content" :key="index">
          【{{ index + 1 }}】{{ item ? item : "空" }} &nbsp;
        </div>
      </div>
    </div>

    <div
      v-if="detail.topic_type_id === 1 || detail.topic_type_id === 2 || detail.topic_type_id === 3 || detail.topic_type_id === 4 || detail.topic_type_id === 5 || detail.topic_type_id === 22">
      <div class="zuoda" style="font-size: 16px;" v-if="detail.word_data != null">
        <div>{{detail.word_data}}</div>
      </div>
      <div class="zuodatitle box_sizing">作答内容</div>
      <mp3-tool ref="mp3" :src-url="detail.extend.content" @showDuration="getDuration"></mp3-tool>
      <div class="box_sizing">
        <div class="zuodatitle box_sizing">AI识别</div>
        <div class="ai_box">
          <div class="ai_level_type">
            <div class="ai_level_type_color goodcolor"></div>
            <div>Good</div>
          </div>
          <div class="ai_level_type">
            <div class="ai_level_type_color badcolor"></div>
            <div>Bad</div>
          </div>
          <div class="ai_level_type">
            <div class="ai_level_type_color averagecolor"></div>
            <div>Average</div>
          </div>
        </div>
        <div v-html="detail.extend.ai_result"></div>
      </div>
    </div>



  </div>
</template>

<style scoped>
.onebox {
  margin-top: 20px;
  padding: 0;
}
.zuoda {
  padding: 0;
}
.words .text {
  margin-right: 10rpx;
  word-wrap: break-word;
}

.error {
  margin-right: 3px;
  color: #666667;
  text-decoration: line-through;
}

.success {
  margin-right: 3px;

  color: #85d88d;
}

.omit {
  margin-right: 3px;

  color: #dd786e;
}
</style>
