<template>
  <div class="mp3-tool">
    <img src="@/assets/image/zanting.png" class="audio-image" v-if="playStatus" alt="" @click="play()">
    <img src="@/assets/image/bofang.png" class="audio-image" v-else alt="" @click="play()">
    <div style="display: flex;align-items: center;">
      <el-select size="mini" v-model="playbackRate" placeholder="请选择" @change="changePlaybackRate">
        <el-option
          v-for="item in playbackRates"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>

    <div class="audio_container">
      <div class="audio_container_box">
        <div class="audio_container_box_jindu progress-container" @click="seek($event)">
          <div class="jindu" :style="{ width: scrollerwidth }"></div>
        </div>
        <div class="audio_container_box_time">{{ timeLength }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    srcUrl: {
      default: ""
    }
  },

  data() {
    return {
      audio:         null,
      playStatus:    false,//播放状态
      duration:      0,  //音频时长
      left:          0,//剩余时长
      scrollerwidth: "0%",      //进度条
      timer:         null,      //定时器
      playbackRate: 1.0,
      playbackRates: [
        {
          label: 'x2.0',
          value: 2.0
        },
        {
          label: 'x1.5',
          value: 1.5
        },
        {
          label: 'x1.25',
          value: 1.25
        },
        {
          label: 'x1.0',
          value: 1.0
        },
        {
          label: 'x0.75',
          value: 0.75
        },
        {
          label: 'x0.5',
          value: .5
        }
      ]
    }
  },
  computed: {
    timeLength: {
      get() {
        return this.convertToTime(parseInt(this.left));
      },
      set(value) {
        console.log('value',value);
        return parseInt(value)
      }
    }
  },
  watch:    {
    left:   {
      handler(newValue) {
        console.log('newValue',newValue);
        this.timeLength = this.convertToTime(parseInt(newValue))
        console.log('timeLength',this.timeLength);
        this.scrollerwidth = this.computedPer()
      }
    },
    srcUrl: {
      handler(newValue) {
        console.log('音乐返回值', newValue)
        this.initAudio()
      }
    }
  },

  created() {
    this.initAudio()
  },
  destroyed() {
    this.audio.pause();
  },
  methods: {
    seek(event) {
      if(this.playStatus) {
        const progressContainer = this.$el.querySelector('.progress-container');
        const rect = progressContainer.getBoundingClientRect();
        const offsetX = event.clientX - rect.left;
        const width = rect.width;
        const newTime = (offsetX / width) * this.duration;
        this.audio.currentTime = newTime;
        let that =this;
        setTimeout(function(){
          that.left = that.duration - newTime;
        },100);
      }
    },
    changePlaybackRate(e) {
      this.audio.playbackRate = this.playbackRate;
    },
    //初始化音频文件
    initAudio() {
      let _ = this;
      if (_.srcUrl === "") {
        console.log('src-url未设置,没有音频文件');
        return;
      }
      _.audio = new Audio();
      _.audio.src = _.srcUrl;
      _.audio.addEventListener('canplaythrough', function () {
        _.duration = parseInt(_.audio.duration)
        _.left = parseInt(_.audio.duration)
        _.timeLength = _.convertToTime(_.duration)
        _.$emit("showDuration",  _.duration);
      })
    },
    //播放暂停
    play() {
      let _ = this;
      if (this.audio === null) {
        alert("请配置音频资源");
        return
      }
      if (_.playStatus) {
        //暂停
        _.audio.pause()
        _.playStatus = false
        clearInterval(this.timer)
      } else {
        //播放
        _.timer = setInterval(function () {
          if (_.left > 0) {
            _.left = _.left-1*_.playbackRate;
          } else {
            _.left = _.duration
            _.playStatus = false
            clearInterval(_.timer)
          }
        }, 1000)
        _.audio.play()
        _.audio.playbackRate = _.playbackRate;
        _.playStatus = true
      }
      _.$emit("showPlayStatus", _.playStatus);
    },
    convertToTime(times) {
      if (typeof times === 'number') {
        if (times <= 0) {
          return '00:00:00';
        } else {
          let hh = parseInt(times / 3600); //小时
          let shh = times - hh * 3600;
          let ii = parseInt(shh / 60);
          let ss = shh - ii * 60;
          return (hh < 10 ? '0' + hh : hh) + ':' + (ii < 10 ? '0' + ii : ii) + ':' + (ss < 10 ? '0' + ss : ss);
        }
      } else {
        return '00:00:00';
      }
    },
    computedPer() {
      let per = parseInt((this.duration - this.left) / this.duration * 100)
      return per + "%";
    }
  }
}
;
</script>
<style scoped>
.mp3-tool {
  display: flex;
}

.audio-image {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

.audio_container {
  padding-left: 20px;
  width: 100%;
}

.audio_container_box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.audio_container_box_jindu {
  width: 100%;
  height: 6px;
  border-radius: 5px;
  overflow: hidden;
  background: rgba(238, 238, 238, 1);
  margin-right: 10px;
}

.jindu {
  height: 100%;
  border-radius: 5px;
  background: rgba(246, 112, 75, 1);
  transition-duration: 1s;
}

.audio_container_box_time {
  margin-right: 10px;
}
.el-select {
  width: 80px;
}
</style>
